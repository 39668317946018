import { featureCollection } from '@turf/helpers'
import { csvFormat } from 'd3-dsv'
import saveAs from 'file-saver'
import { FeatureCollection } from 'geojson'
import _ from 'lodash'
import { DateTime } from 'luxon'
import { Expression } from 'mapbox-gl'
import { action, computed, makeObservable, observable, toJS } from 'mobx'

import { renderDate } from '@/common/utils/date'
import { formatCount, formatDistance, formatNumber } from '@/common/utils/formatting'
import { metersToKmOrMiles } from '@/common/utils/numbers'
import { fromMinutesToHHMM, MINUTES_IN_AN_HOUR } from '@/common/utils/time'
import { queryClientInstance } from '@/modules/api/queryClient'
import { apipyRequest, internalApi } from '@/modules/api/request'
import { HourFilters } from '@/modules/evaluationMap/types'
import i18n from '@/modules/i18n/i18n'
import layerStore from '@/modules/layers/layerStore'
import { monitorMessage } from '@/modules/monitoring'
import appUIStore from '@/stores/appUIStore'

import { COLOR_SCHEMES, ColorBin, DiscreteQuantileMapColorScale } from '../map/components/mapScale'
import { parkingHeatmapStore, tripsHeatmapStore } from '../map/heatmap/pointLocationHeatmapStore'

import { EvaluationAggregationType } from './EvaluationAggregationType'
import { EvaluationMode } from './EvaluationAggregationType'
import { QueryResponse, ShapeDataObjects } from './types'

type Counts = {
  value: number
  percent: number
}

type DateRangeInput = {
  start?: DateTime
  end?: DateTime | null
  fetch?: boolean
}

export class EvaluationMapStore {
  mode: EvaluationMode = 'trips'
  aggregation: EvaluationAggregationType = EvaluationAggregationType.TRIPS_ORIGINS
  operator: string = 'all'
  vehicleType?: string
  weekdayWeekendToggle: boolean = true
  ready: boolean = false
  layerIsLoading: boolean = false
  mapLoaded: boolean = false
  dateRange = {
    start: DateTime.now().minus({ months: 5 }).startOf('month'),
    end: DateTime.now(),
  }
  hourFilters: HourFilters = [0, 24]
  shapeData?: ShapeDataObjects | null // this is the response from the server
  bufferSize?: number
  parkingDurationFilter: number | undefined // undefined disables the filter
  totalCount?: number // sum count including NOT_IN_SHAPE
  totalQueryCount?: number // sum count excluding NOT_IN_SHAPE
  activeLayerUUID?: string
  selectedShape?: string // the id when the user clicks on an id
  payload: any // record the query we most recently sent

  constructor() {
    makeObservable(this, {
      mode: observable,
      aggregation: observable,
      operator: observable,
      vehicleType: observable,
      weekdayWeekendToggle: observable,
      ready: observable,
      layerIsLoading: observable,
      mapLoaded: observable,
      dateRange: observable,
      hourFilters: observable,
      shapeData: observable,
      bufferSize: observable,
      parkingDurationFilter: observable,
      totalCount: observable,
      totalQueryCount: observable,
      activeLayerUUID: observable,
      selectedShape: observable,
      setMode: action,
      resetToDefaults: action,
      activeLayer: computed,
      setLayerIsLoading: action,
      hourFiltersEnabled: computed,
      parkingDurationFiltersEnabled: computed,
      totalDaysSelected: computed,
      setWeekdayWeekendToggle: action,
      legendTitle: computed,
      weekdayWeekend: computed,
      receiveQueryData: action,
      shapeFilterName: computed,
      setQueryParams: action,
      runQuery: action,
      setReady: action,
      setMapLoaded: action,
      reset: action,
      updateMetro: action,
      mapTitle: computed,
      statTitle: computed,
      statCaption: computed,
      aggregationOptions: computed,
      allValues: computed,
      mapColorScale: computed,
      legendBins: computed,
      aggregationFilterText: computed,
      csvDownloadOptions: computed,
      hasParkingHeatmap: computed,
      hasTripsHeatmap: computed,
      tripsHeatmapType: computed,
      geojson: computed,
      matchExpressions: computed,
      queryDataCount: computed,
      queryPercent: computed,
      queryAverage: computed,
    })
  }

  setMode(mode: EvaluationMode) {
    this.mode = mode
    this.resetToDefaults()
  }

  resetToDefaults = () => {
    this.weekdayWeekendToggle = true
    parkingHeatmapStore.setVisibility(false)
    tripsHeatmapStore.setVisibility(false)
    this.selectedShape = undefined
    this.activeLayerUUID = layerStore.firstLayerUUID!
    this.setMapLoaded()
  }

  get activeLayer() {
    return layerStore.getLayer(this.activeLayerUUID)!
  }

  setLayerIsLoading(layerIsLoading: boolean) {
    this.layerIsLoading = layerIsLoading
  }

  get hourFiltersEnabled() {
    return (
      this.aggregation.supportsHourFilters &&
      !(this.aggregation.mode === 'trips' && !this.activeLayer.hasHourlyTrips)
    )
  }

  get parkingDurationFiltersEnabled() {
    return (
      this.aggregation === EvaluationAggregationType.PARKING_TOTAL_EVENTS ||
      this.aggregation === EvaluationAggregationType.PARKING_DAILY_EVENTS
    )
  }

  get totalDaysSelected(): number {
    const dateFrom = this.dateRange.start
    const dateTo = this.dateRange.end
    if (!dateFrom || !dateTo) return 0
    return dateTo.diff(dateFrom, 'days').days + 1
  }

  setQueryParams(params: {
    dateRange: DateRangeInput
    hourFilters: HourFilters
    aggregation: string
    layerUUID: string | undefined
    operator: string
    vehicleType?: string
    bufferSize?: number
    parkingDurationFilter: number | undefined
    selectedShape: string | undefined
  }) {
    this.dateRange.start = params.dateRange.start!.startOf('day')
    this.dateRange.end = params.dateRange.end!.startOf('day')
    if (this.dateRange.start > this.dateRange.end) {
      const tmp = this.dateRange.start
      this.dateRange.start = this.dateRange.end
      this.dateRange.end = tmp
    }

    this.hourFilters = params.hourFilters

    if (params.aggregation !== this.aggregation.id) {
      this.aggregation = EvaluationAggregationType.fromValue(params.aggregation)!
    }

    if (params.layerUUID && params.layerUUID !== this.activeLayerUUID) {
      this.activeLayerUUID = params.layerUUID
      this.setLayerIsLoading(true)
      this.activeLayer.loadShapesFromDb().then(() => this.setLayerIsLoading(false))
    }

    this.operator = params.operator
    this.vehicleType = params.vehicleType
    this.bufferSize = params.bufferSize
    this.parkingDurationFilter = params.parkingDurationFilter
    this.selectedShape = params.selectedShape

    this.runQuery()
  }

  setWeekdayWeekendToggle(weekdayWeekendToggle: boolean) {
    if (this.weekdayWeekendToggle === weekdayWeekendToggle) return
    this.weekdayWeekendToggle = weekdayWeekendToggle
    this.runQuery()
  }

  get legendTitle() {
    return this.aggregation.legendTitle(
      this.aggregation.isDistance ? appUIStore.distanceUnits : undefined
    )
  }

  get weekdayWeekend() {
    return this.weekdayWeekendToggle ? 'weekday' : 'weekend'
  }

  receiveQueryData(response: QueryResponse) {
    if (this.mode === 'parking') {
      _.each(response.shape_data, o => {
        o.total_parking_events = o.count
        o.daily_parking_events = o.total_parking_events / this.totalDaysSelected
        o.total_parked_duration /= 60
      })
    } else if (this.mode === 'operator_drop_offs') {
      // nothing here right now
    } else if (this.aggregation.isDistance) {
      // trip queries with distance aggregation
      _.each(response.shape_data, o => {
        o.average_distance = metersToKmOrMiles(o.orig || o.dest, appUIStore.region!.isMetric)
      })
    } else {
      // all other trip queries
      _.each(response.shape_data, o => {
        o.daily_orig = o.orig! / this.totalDaysSelected
        o.daily_dest = o.dest! / this.totalDaysSelected
      })
    }

    this.totalCount = _(response.shape_data)
      .map(this.aggregation.geojsonAttribute)
      .filter(v => !_.isNaN(v))
      .sum()
    this.totalQueryCount = _(response.shape_data)
      .omit('NOT_IN_SHAPE')
      .map(this.aggregation.geojsonAttribute)
      .filter(v => !_.isNaN(v))
      .sum()

    if (this.activeLayer.doc.layer_slug === 'tracts') {
      this.getCensusData(response.shape_data, appUIStore.region!.regionId)
      return
    }
    this.shapeData = response.shape_data
  }

  getCensusData(shapes: ShapeDataObjects, regionId: string) {
    internalApi.layers
      .getCensusDataForShapeLayer({ regionId, shapeLayerUuid: this.activeLayerUUID! })
      .then(response => {
        Object.entries(response).forEach(([id, censusData]) => {
          shapes[id].pct_nonwhite = censusData.pctNonwhite
          shapes[id].median_income = censusData.medianIncome
        })
        this.shapeData = shapes
        this.setReady(true)
      })
      .catch(error => {
        if (
          error === 'layer_does_not_exist' ||
          error === 'table_does_not_exist' ||
          error === 'column_does_not_exist'
        ) {
          // this can happen when the configuration of layer has been updated but the views
          // have not yet been reprocessed
          // Popup store had been disconnected from all functionality. Leaving this commented out
          // for when this page is refactored
          // popupStore.create({
          //   size: 'mini',
          //   title: 'Warning',
          //   content: 'This layer is still processing, please try again later.',
          // })
          this.shapeData = shapes
          this.setReady(true)
        }
      })
  }

  downloadCSV() {
    const startStr = renderDate(this.dateRange.start, DateTime.DATE_SHORT)
    const endStr = renderDate(this.dateRange.end, DateTime.DATE_SHORT)
    const what = {
      trips: 'Trip',
      parking: 'Parking event',
      operator_drop_offs: 'Operator drop-off',
    }[this.mode]
    const filename = `${what} counts for ${this.activeLayer.layerName} from ${startStr} to ${endStr}.csv`
    let columns = {
      parking: [
        'date',
        'operator',
        'vehicle_type',
        'shape_uuid',
        'shape_name',
        'count',
        'total_parked_duration',
        'average_parked_duration',
      ],
      operator_drop_offs: ['date', 'operator', 'vehicle_type', 'shape_uuid', 'shape_name', 'count'],
      trips: ['date', 'operator', 'vehicle_type', 'shape_uuid', 'shape_name', 'count'],
    }[this.mode]

    if (
      _.includes(
        [
          EvaluationAggregationType.TRIPS_ORIGIN_OR_DESTINATION,
          EvaluationAggregationType.TRIPS_AVERAGE_ORIGIN_DISTANCE,
          EvaluationAggregationType.TRIPS_AVERAGE_DESTINATION_DISTANCE,
        ],
        this.aggregation
      )
    ) {
      const data = _.map(
        _.pickBy(this.shapeData, (s, k) => k !== 'NOT_IN_SHAPE'),
        (v, k) => ({ shape_name: v.shape_name, shape_uuid: k, ...this.geojsonGetValue(k) })
      )
      columns =
        this.aggregation === EvaluationAggregationType.TRIPS_ORIGIN_OR_DESTINATION
          ? ['shape_uuid', 'shape_name', 'value', 'percent']
          : ['shape_uuid', 'shape_name', 'value']

      const text = this.csvDownloadOptions.header + '\n' + csvFormat(data as any, columns)
      const blob = new Blob([text], { type: 'text/csv' })
      // @ts-ignore
      saveAs(blob, filename)
    } else {
      const endpoint = {
        operator_drop_offs: '/deployments/by_day_spatial',
        parking: '/parking_events/by_day_spatial',
        trips: '/trips/by_day_spatial',
      }[this.mode]
      apipyRequest(endpoint, this.payload, { reject: true })
        .then(response => {
          const data = _.orderBy(
            _.filter(response.shape_data_by_day, o => o.shape_uuid !== 'NOT_IN_SHAPE'),
            ['date', 'operator', 'vehicle_type', 'shape_name'],
            ['desc']
          )
          const text = this.csvDownloadOptions.header + '\n' + csvFormat(data, columns)
          const blob = new Blob([text], { type: 'text/csv' })
          // @ts-ignore
          saveAs(blob, filename)
        })
        .catch(err => {
          monitorMessage(`${endpoint} error: ${err}`)
        })
    }
  }

  /**
   * This computed is used to identify which trip aggregations need to apply an origin or
   * destination filter
   */
  get shapeFilterName(): 'origin' | 'destination' | undefined {
    switch (this.aggregation) {
      case EvaluationAggregationType.TRIPS_ORIGINS:
      case EvaluationAggregationType.TRIPS_DAILY_ORIGINS:
        return 'origin'
      case EvaluationAggregationType.TRIPS_DESTINATIONS:
      case EvaluationAggregationType.TRIPS_DAILY_DESTINATIONS:
        return 'destination'
      default:
        return undefined
    }
  }

  runQuery() {
    this.shapeData = undefined
    this.totalCount = 0
    this.totalQueryCount = 0
    this.setReady(false)

    if (!this.activeLayerUUID) {
      // this can happen when we don't have permissions to trips, so we're setting the
      // mode to parking, but the layers haven't loaded yet
      return
    }

    this.payload = {
      start_date: this.dateRange.start?.toISODate(),
      end_date: this.dateRange.end?.toISODate(),
      vehicle_type: this.vehicleType !== 'all' ? this.vehicleType : undefined,
      operators: this.operator !== 'all' ? [this.operator] : undefined,
      shape_layer_uuid: this.activeLayerUUID,
      buffer: this.bufferSize,
      agg: this.aggregation.isDistance ? 'average_distance' : 'total_count',
    }

    if (this.mode === 'trips') {
      switch (this.aggregation) {
        case EvaluationAggregationType.TRIPS_ORIGINS:
        case EvaluationAggregationType.TRIPS_DAILY_ORIGINS:
        case EvaluationAggregationType.TRIPS_AVERAGE_ORIGIN_DISTANCE:
          this.payload.direction = 'orig'
          break
        case EvaluationAggregationType.TRIPS_DESTINATIONS:
        case EvaluationAggregationType.TRIPS_DAILY_DESTINATIONS:
        case EvaluationAggregationType.TRIPS_AVERAGE_DESTINATION_DISTANCE:
          this.payload.direction = 'dest'
          break
        default:
          this.payload.direction = this.aggregation.id
          break
      }
    }

    if (this.hourFiltersEnabled) {
      // the api uses inclusive hour filters, but frontend excludes the max hour filter
      this.payload.hour_filters = [this.hourFilters[0], this.hourFilters[1] - 1]
    }

    if (this.parkingDurationFiltersEnabled && this.parkingDurationFilter !== undefined) {
      this.payload.parked_duration = Number(this.parkingDurationFilter)
    }

    if (this.mode === 'trips' && this.shapeFilterName) {
      // we've clicked on a place and we want to filter to it
      this.payload.shape_filter = this.selectedShape
    }

    const endpoint = {
      trips: '/trips/spatial',
      operator_drop_offs: '/deployments/spatial',
      parking: '/parking_events/spatial',
    }[this.mode]

    queryClientInstance
      .fetchQuery({
        queryKey: [endpoint, this.payload],
        queryFn: async () =>
          apipyRequest(endpoint, this.payload, { reject: true }).then(response => response),
      })
      .then(response => {
        this.receiveQueryData(structuredClone(response))
        this.setReady(true)
      })
      .then(() => {
        parkingHeatmapStore.apiQueryParams = undefined
        if (tripsHeatmapStore.isVisible) {
          tripsHeatmapStore.query(this.tripsHeatmapType)
        } else if (parkingHeatmapStore.isVisible) {
          parkingHeatmapStore.apiQueryParams = {
            startDate: this.payload.start_date,
            endDate: this.payload.end_date,
            startTime:
              this.hourFilters[0] > 0
                ? fromMinutesToHHMM(this.hourFilters[0] * MINUTES_IN_AN_HOUR)
                : undefined,
            endTime:
              this.hourFilters[1] < 24
                ? fromMinutesToHHMM(this.hourFilters[1] * MINUTES_IN_AN_HOUR)
                : undefined,
            operators: this.payload.operators,
            vehicleTypes: [...(this.payload.vehicle_type ? [this.payload.vehicle_type] : [])],
            minParkingDuration: this.payload.parked_duration ?? undefined,
          }
          parkingHeatmapStore.query()
        }
      })
      .catch(error => {
        if (
          error === 'layer_does_not_exist' ||
          error === 'table_does_not_exist' ||
          error === 'column_does_not_exist'
        ) {
          // this can happen when the configuration of layer has been updated but the views
          // have not yet been reprocessed
          // Popup store had been disconnected from all functionality. Leaving this commented out
          // for when this page is refactored
          // popupStore.create({
          //   size: 'mini',
          //   title: 'Warning',
          //   content: 'This layer is still processing, please try again later.',
          // })
          this.setReady(true)
        }
      })
  }

  setReady(ready: boolean) {
    this.ready = ready
  }

  setMapLoaded() {
    this.mapLoaded = true
  }

  reset() {
    this.setReady(false)
    this.mapLoaded = false
  }

  updateMetro() {
    this.shapeData = null
    this.totalCount = undefined
    this.totalQueryCount = undefined
    this.aggregation = EvaluationAggregationType.TRIPS_ORIGINS
  }

  get mapTitle(): string {
    const { mode } = this
    switch (mode) {
      case 'operator_drop_offs':
        return i18n.t('evaluationMap.operatorDropOffs', 'Operator Drop-Offs Map')
      case 'parking':
        return i18n.t('evaluationMap.parkingMap', 'Parking Map')
      case 'trips':
        return i18n.t('evaluationMap.tripsMap', 'Trips Map')
    }
  }

  get statTitle(): string {
    return this.aggregation.statTitle(
      this.aggregation.isDistance ? appUIStore.distanceUnits : undefined
    )
  }

  get statCaption(): string {
    return ` ${this.statTitle}`
  }

  get aggregationOptions(): { value: string; text: string }[] {
    return EvaluationAggregationType.byMode(this.mode)
      .filter(
        aggregationType =>
          !aggregationType.requiresLayerIntersect ||
          (this.activeLayer && this.activeLayer.hasIntersect)
      )
      .map(aggregationType => ({
        value: aggregationType.id,
        text: aggregationType.title,
      }))
  }

  get allValues() {
    const data = _(this.shapeData)
      // @ts-ignore
      .filter((obj, shape_uuid) => shape_uuid !== 'NOT_IN_SHAPE')
      .map(this.aggregation.geojsonAttribute)
      .value()

    data.sort()
    return data
  }

  get mapColorScale() {
    let colorScheme: string[]
    if (
      this.mode === 'trips' &&
      ((this.aggregation === EvaluationAggregationType.TRIPS_ORIGINS && this.selectedShape) ||
        (this.aggregation === EvaluationAggregationType.TRIPS_DESTINATIONS &&
          !this.selectedShape) ||
        (this.aggregation === EvaluationAggregationType.TRIPS_DAILY_ORIGINS &&
          this.selectedShape) ||
        (this.aggregation === EvaluationAggregationType.TRIPS_DAILY_DESTINATIONS &&
          !this.selectedShape) ||
        this.aggregation === EvaluationAggregationType.TRIPS_AVERAGE_DESTINATION_DISTANCE)
    ) {
      colorScheme = COLOR_SCHEMES.YELLOW_TO_RED_7_BINS
    } else {
      colorScheme = COLOR_SCHEMES.YELLOW_TO_BLUE_7_BINS
    }

    return new DiscreteQuantileMapColorScale(colorScheme, this.allValues)
  }

  get legendBins(): ColorBin[] {
    if (_.isEmpty(this.shapeData)) return []
    return this.mapColorScale.bins.map(bin => {
      return {
        value:
          typeof bin.value === 'number'
            ? this.aggregation.isDistance
              ? formatDistance(bin.value)
              : formatCount(bin.value)
            : bin.value,
        displayMin: bin.displayMin,
        displayMax: bin.displayMax,
        color: bin.color,
      }
    })
  }

  geojsonGetValue(id: string): Counts {
    if (!this.shapeData || !this.shapeData[id.toString()]) return { value: 0, percent: 0 }
    const value = (this.shapeData[id.toString()] as any)[this.aggregation.geojsonAttribute]
    return {
      value,
      percent: this.totalQueryCount ? _.round((value / this.totalQueryCount) * 100, 1) : NaN,
    }
  }

  geojsonGetColor(val: Counts): string {
    return this.mapColorScale.getMapColor(val.value)
  }

  get aggregationFilterText(): string | undefined {
    const swappedValues: { [key: string]: string } = {
      [EvaluationAggregationType.TRIPS_ORIGINS.id]: EvaluationAggregationType.TRIPS_DESTINATIONS.id,
      [EvaluationAggregationType.TRIPS_DESTINATIONS.id]: EvaluationAggregationType.TRIPS_ORIGINS.id,
      [EvaluationAggregationType.TRIPS_DAILY_ORIGINS.id]:
        EvaluationAggregationType.TRIPS_DAILY_DESTINATIONS.id,
      [EvaluationAggregationType.TRIPS_DAILY_DESTINATIONS.id]:
        EvaluationAggregationType.TRIPS_DAILY_ORIGINS.id,
    }
    const targetValue =
      this.mode === 'trips' && this.selectedShape && this.aggregation.id in swappedValues
        ? swappedValues[this.aggregation.id]
        : this.aggregation.id

    return _.find(this.aggregationOptions, t => t.value === targetValue)?.text
  }

  get csvDownloadOptions() {
    return {
      eventName: 'Evaluation Map',
      header: `geography=${this.activeLayer.layerName},total count=${this.totalCount},operator=${this.operator},vehicle type=${this.vehicleType},buffer=${this.bufferSize}m`,
    }
  }

  get hasParkingHeatmap(): boolean {
    return this.mode === 'parking'
  }

  get hasTripsHeatmap(): boolean {
    return this.mode === 'trips'
  }

  get tripsHeatmapType(): undefined | 'origin' | 'destination' {
    if (!this.hasTripsHeatmap) return undefined

    switch (this.aggregation) {
      case EvaluationAggregationType.TRIPS_ORIGINS:
      case EvaluationAggregationType.TRIPS_DAILY_ORIGINS:
      case EvaluationAggregationType.TRIPS_ORIGIN_OR_DESTINATION:
      case EvaluationAggregationType.TRIPS_AVERAGE_ORIGIN_DISTANCE:
        return 'origin'
      default:
        return 'destination'
    }
  }

  get heatmapTitle(): string {
    if (this.hasTripsHeatmap) {
      switch (this.tripsHeatmapType) {
        case 'origin':
          return i18n.t('evaluationMap.tripOriginHeatmapTitle', 'Trip Origins')
        case 'destination':
        default:
          return i18n.t('evaluationMap.tripDestinationHeatmapTitle', 'Trip Destinations')
      }
    } else {
      return i18n.t('evaluationMap.parkingHeatmapTitle', 'Frequent Parking')
    }
  }

  get geojson(): FeatureCollection {
    if (!this.activeLayer) {
      return featureCollection([])
    }

    return featureCollection(
      this.activeLayer.geojson.features.map(f => {
        const id = f.properties!.id
        const val = this.geojsonGetValue(id)
        return {
          type: 'Feature',
          geometry: toJS(f.geometry),
          properties: {
            id,
            shape_id: id, //for export, removed id column in all map csv downloads (temp workaround)
            ...val, // for export
            color: this.geojsonGetColor(val),
            name: f.properties!.name,
          },
        }
      })
    )
  }

  get matchExpressions() {
    // Define match expressions for color
    // to associate local json values with vector tile sources
    // https://docs.mapbox.com/mapbox-gl-js/example/data-join/

    const defaultColor = 'rgb(255, 255, 255)'

    // set defaults for operators without availability data
    let colorMatchExpression: string | Expression = defaultColor

    // iterate over availabilityData values and construct match expressions
    if (this.shapeData) {
      colorMatchExpression = ['match', ['get', 'shape_uuid']]

      _.forOwn(this.shapeData, (r, shapeId) => {
        const val = this.geojsonGetValue(shapeId)

        const color = this.geojsonGetColor(val)

        if (Array.isArray(colorMatchExpression)) colorMatchExpression.push(shapeId, color)
      })

      // push defaults
      colorMatchExpression.push(defaultColor)
    }

    return { color: colorMatchExpression }
  }

  get queryDataCount(): number {
    return this.shapeData ? Object.keys(this.shapeData).length - 1 : 0
  }

  get queryPercent(): number {
    if (!this.totalQueryCount || !this.totalCount) return 0
    return Math.ceil((this.totalQueryCount / this.totalCount) * 100)
  }

  get queryAverage(): string | undefined {
    return this.totalCount && this.totalQueryCount && this.queryDataCount
      ? formatNumber(this.totalQueryCount / this.queryDataCount, 2)
      : undefined
  }
}

const evaluationMapStore = new EvaluationMapStore()

export default evaluationMapStore
