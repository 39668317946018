import { useCallback } from 'react'

type Value = number | undefined | null

export const CENTS_IN_DOLLAR = 100

/**
 * Throws errors if minDecimals is greater than maxDecimals or either are negative
 * @returns minDecimals
 */
function validateFractionDigits(maxDecimals: number, minDecimals?: number) {
  minDecimals = minDecimals ?? maxDecimals
  if (minDecimals > maxDecimals)
    throw RangeError('minDecimals must be less than or equal to maxDecimals')
  if (minDecimals < 0 || maxDecimals < 0) throw RangeError('decimalPlaces must be greater than 0')
  return minDecimals
}

/**
 * @returns Function that formats currency value according to region_id
 */
export const useFormatCurrency = (currencyCode?: string) => {
  return useCallback(
    (value: Value) => formatCurrency(value, window.navigator.language, currencyCode),
    [currencyCode]
  )
}

/**
 * Format number into localized currency
 * @param value Number to be formatted as currency (`1000`)
 * @param localeCode Locale code to format (`en-us`)
 * @param currencyCode Currency code to format (`USD`)
 * @returns Number formatted as currency string (`$1,000.00`)
 */
export const formatCurrency = (
  value: Value,
  localeCode: string = 'en-us',
  currencyCode: string = 'USD',
  minimumFractionDigits: number = 2,
  maximumFractionDigits: number = 2
) => {
  return new Intl.NumberFormat(localeCode, {
    style: 'currency',
    currency: currencyCode,
    minimumFractionDigits,
    maximumFractionDigits,
  }).format(value || 0)
}

/**
 * Format number with localization and specified number of decimals
 * @param value the number to format (`1000.230`)
 * @param maxDecimals (default: 0) If the only value specified, all numbers will have this many decimals (`2`)
 * @param minDecimals (default: undefined) If specified, numbers can have fewer decimal places than the max (`0`)
 * @returns Number formatted as string (`1,000.23`)
 */
export function formatNumber(value: Value, maxDecimals: number = 0, minDecimals?: number) {
  minDecimals = validateFractionDigits(maxDecimals, minDecimals)
  return new Intl.NumberFormat(window.navigator.language, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
  }).format(value || 0)
}

/**
 * Format percent with localization and specified number of decimals
 * @param value the number to format
 * @param maxDecimals (default: 1) If the only value specified, all numbers will have this many decimals
 * @param minDecimals (default: undefined) If specified, numbers can have fewer decimal places than the max
 */
export function formatPercent(value: Value, maxDecimals: number = 1, minDecimals?: number) {
  minDecimals = validateFractionDigits(maxDecimals, minDecimals)
  return new Intl.NumberFormat(window.navigator.language, {
    minimumFractionDigits: minDecimals,
    maximumFractionDigits: maxDecimals,
    style: 'percent',
  }).format(value || 0)
}

// See: https://github.com/unicode-org/cldr/blob/main/common/validity/unit.xml
type Unit = 'hour' | 'kilometer' | 'meter' | 'mile'
type UnitDisplay = Intl.NumberFormatOptions['unitDisplay']

export function formatUnit({
  value,
  unit,
  unitDisplay,
  minDecimals,
  maxDecimals,
}: {
  value: Value
  unit: Unit
  unitDisplay: UnitDisplay
  minDecimals?: number
  maxDecimals?: number
}) {
  if (minDecimals && maxDecimals) validateFractionDigits(maxDecimals, minDecimals)
  return new Intl.NumberFormat(window.navigator.language, {
    style: 'unit',
    unit,
    ...(minDecimals && { minimumFractionDigits: minDecimals }),
    ...(maxDecimals && { maximumFractionDigits: maxDecimals }),
    ...(unitDisplay && { unitDisplay }),
  }).format(value || 0)
}

export const formatCount = (count: number) => {
  if (count > 0 && count < 0.1) return '0.1'
  if (count > 0 && count < 1) return formatNumber(count, 1)
  return formatNumber(count)
}

export const formatDistance = (count: number) => (count ? formatNumber(count, 2) : 'n/a')
