/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { InvoiceRequest } from '../models/InvoiceRequest'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class InvoicesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Send Invoice Email
   * @returns any Successful Response
   * @throws ApiError
   */
  public sendInvoiceEmail({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: InvoiceRequest
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/mobility/invoice/send_invoice_email',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
