import { PickersShortcutsItem } from '@mui/x-date-pickers/PickersShortcuts'
import { DateRange } from '@mui/x-date-pickers-pro'
import { isDate, range } from 'lodash'
import { DateTime, DateTimeFormatOptions, Info, LocaleOptions } from 'luxon'

import { startCase } from '@/common/utils/text'
import i18n from '@/modules/i18n/i18n'
export const ISO_DAYS_OF_WEEK = [1, 2, 3, 4, 5, 6, 7]

export function toJSDateLocal(dt: DateTime) {
  return dt.setZone('local', { keepLocalTime: true }).toJSDate()
}

export function fromJSDateLocal(jsDate: Date) {
  return DateTime.fromJSDate(jsDate).startOf('day').setZone('utc', {
    keepLocalTime: true,
  })
}

export function renderDate(
  dateTime: DateTime | null | undefined,
  format: LocaleOptions & DateTimeFormatOptions
) {
  /*
  dateTime is a Luxon DateTime and format is Luxon DateTime format
   */
  let locale
  try {
    locale = Intl.DateTimeFormat().resolvedOptions().locale
  } catch (e) {
    locale = 'en-US'
  }

  return dateTime?.setLocale(locale).toLocaleString(format)
}

/**
 * Creates a human-friendly string representation of the time interval between
 * two dates. If no end date is supplied, the function uses the current time.
 */
export function renderDateRelative(startDate: Date, endDate?: Date): string {
  const startDateTime = DateTime.fromJSDate(startDate)
  let endDateTime
  if (endDate) endDateTime = DateTime.fromJSDate(endDate)
  const relativeString = startDateTime.toRelative({ base: endDateTime, round: false }) as string
  /**
   * The "rounding" luxon does for toRelative() is actually a floor, so
   * we need to do some extra steps to get a truly rounded relative date
   */
  const relativeArray = relativeString.split(' ')
  const timeDiff = Math.round(Number(relativeArray[0]))
  return `${timeDiff} ${relativeArray[1]}`
}

type DateStyleType = Intl.DateTimeFormatOptions['dateStyle'] | Intl.DateTimeFormatOptions
/**
 * Converts Date instance into readable date string
 * @param {Date | undefined | null} date
 * @param {DateStyleType} dateStyle
 * @returns {string} String in standard or custom format
 */
export const formatDateToText = (
  date: Date | undefined | null,
  dateStyle?: DateStyleType
): string => {
  if (!date) return ''

  if (typeof dateStyle === 'string') {
    return new Intl.DateTimeFormat(navigator.language, {
      dateStyle: dateStyle as Intl.DateTimeFormatOptions['dateStyle'],
    }).format(date)
  } else {
    return new Intl.DateTimeFormat(navigator.language, dateStyle).format(date)
  }
}

/**
 * Converts ISO datetime string into readable date string
 * @param {string | undefined | null} isoDate
 * @param {DateStyleType} dateStyle
 * @returns {string} String in standard or custom format
 */
export const formatDateISOToText = (
  isoDateString: string | undefined | null,
  dateStyle: DateStyleType
): string => formatDateToText(isoToDate(isoDateString), dateStyle)

/**
 * Standardized Date String using ISO 8601
 * https://en.wikipedia.org/wiki/ISO_8601
 * https://xkcd.com/1179
 * @param {any} date:Date
 * @returns {any} String of Date in YYYY/MM/DD format
 */
export function dateToISO(date: Date | undefined | null): string {
  if (!date) return ''

  if (!isDate(date)) return ''

  return DateTime.fromJSDate(date).toISODate()!
}

export function isoToDate(isoDateString: string | undefined | null): Date | undefined {
  if (!isoDateString) return undefined

  const date = new Date(isoDateString)
  date.setUTCMinutes(date.getUTCMinutes() + date.getTimezoneOffset())

  return date
}

const allDayTypes = ['weekday', 'saturday', 'sunday']

/**
 * convert array of day type types to readable format
 * @param {DayTypeTypes[]} dayTypes list of DayTypes
 * @returns {string} list of day types as string
 */
export const formatDayTypeArray = (dayTypes: string[]) => {
  if (JSON.stringify(dayTypes.slice(0).sort()) === JSON.stringify(allDayTypes.slice(0).sort()))
    return i18n.t('common.everyDay', 'Every Day')

  return dayTypes
    .sort((a, b) => allDayTypes.indexOf(a) - allDayTypes.indexOf(b))
    .map(day => i18n.t(`common.${day}`, startCase(day)))
    .join(', ')
}

/**
 * converts daysOfWeek in numbers to days of the week in readable format
 * @param {number[]} daysOfWeek list of days represented by numbers
 * @returns {string} list of day types or days as string
 */
export const formatDaysOfWeekArray = (
  daysOfWeek: number[] | undefined | null,
  weekendDays: number[] = [6, 7]
) => {
  if (!daysOfWeek) return i18n.t('common.everyDay', 'Every Day')
  const cleanDaysOfWeek = [...new Set(daysOfWeek.sort())]
  if (cleanDaysOfWeek.some(dayOfWeek => dayOfWeek < 1 || dayOfWeek > 7))
    throw RangeError('All Days must > 0 and <= 7 as defined by ISO_DAYS_OF_WEEK')
  if (cleanDaysOfWeek.length === 7) return i18n.t('common.everyDay', 'Every Day')
  if (cleanDaysOfWeek.toString() === weekendDays.toString())
    return i18n.t('common.weekends', 'Weekends')
  const weekDays = ISO_DAYS_OF_WEEK.filter(day => !weekendDays.includes(day))
  if (cleanDaysOfWeek.toString() === weekDays.toString())
    return i18n.t('common.weekdays', 'Weekdays')

  return daysOfWeek.map(day => Info.weekdays('short')[day - 1].slice(0, 2)).join(' ')
}

export const getYearsFromDateRange = (minDate: Date, maxDate?: Date): number[] => {
  if (maxDate && maxDate < minDate) throw new Error('minDate must be less than maxDate')

  const startYear = minDate.getFullYear()
  let endYear

  if (maxDate) {
    endYear = maxDate.getFullYear()
  } else {
    const today = new Date(Date.now())
    endYear = today.getFullYear()
  }

  return range(Number(startYear), Number(endYear) + 1, 1)
}

export const snapDateToMonthStart = (start: string) =>
  DateTime.fromJSDate(isoToDate(start)!).startOf('month').toJSDate()

export const snapDateToMonthEnd = (end: string) =>
  DateTime.fromJSDate(isoToDate(end)!).endOf('month').toJSDate()

export const shortcutsItems: PickersShortcutsItem<DateRange<DateTime>>[] = [
  {
    label: 'This Month',
    getValue: () => {
      const today = DateTime.now()
      return [today.startOf('month'), today]
    },
  },
  {
    label: 'Last Month',
    getValue: () => {
      const today = DateTime.now()
      const endOfLastMonth = today.startOf('month').minus({ days: 1 })
      return [endOfLastMonth.startOf('month'), endOfLastMonth]
    },
  },
  {
    label: 'This Quarter',
    getValue: () => {
      const today = DateTime.now()
      return [today.startOf('quarter'), today]
    },
  },
  {
    label: 'Last Quarter',
    getValue: () => {
      const today = DateTime.now()
      const endOfLastQuarter = today.startOf('quarter').minus({ days: 1 })
      return [endOfLastQuarter.startOf('quarter'), endOfLastQuarter]
    },
  },
]

export const formatDateToMMDDYYYY = (date: Date) => {
  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric',
  })
}

/**
 * @param startDate Date object
 * @param endDate Date object
 *
 * @returns Date range string formatted according to the browser's locale
 *           e.g. "2/2/2020 - 2/3/2021"
 */
export function formatDateRange(startDate: Date, endDate?: Date | undefined) {
  const startDateFormatted = startDate.toLocaleDateString()

  if (!endDate) return startDateFormatted + ' -'
  if (startDate !== endDate) return startDateFormatted

  return `${startDateFormatted} - ${endDate.toLocaleDateString()}`
}
