/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AverageOperationalVehiclesDataModel } from '../models/AverageOperationalVehiclesDataModel'
import type { RouteDateRangeResponse } from '../models/RouteDateRangeResponse'
import type { RouteStatisticDataModel } from '../models/RouteStatisticDataModel'
import type { RouteWithTripDataModel } from '../models/RouteWithTripDataModel'
import type { TripSummaryDataModel } from '../models/TripSummaryDataModel'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class RoutesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get External Date Range
   * @returns RouteDateRangeResponse Successful Response
   * @throws ApiError
   */
  public getExternalDateRange({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<RouteDateRangeResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/date_range',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get External Routes Vehicle Types
   * @returns string Successful Response
   * @throws ApiError
   */
  public getExternalRoutesVehicleTypes({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<Array<string>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/vehicle_types',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Route External Statistics
   * @returns RouteStatisticDataModel Successful Response
   * @throws ApiError
   */
  public routeExternalStatistics({
    regionId,
    startDate,
    endDate,
    startTimeHour,
    endTimeHour = 23,
    operator,
    vehicleType,
    daysOfWeek,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Start Hour
     */
    startTimeHour?: number
    /**
     * End Hour
     */
    endTimeHour?: number
    /**
     * Operator
     */
    operator?: string | null
    /**
     * Include routes for this vehicle type
     */
    vehicleType?: string | null
    /**
     * Days of the Week to include
     */
    daysOfWeek?: Array<number>
  }): CancelablePromise<RouteStatisticDataModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/statistics',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time_hour: startTimeHour,
        end_time_hour: endTimeHour,
        operator: operator,
        vehicle_type: vehicleType,
        days_of_week: daysOfWeek,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Routes External
   * @returns RouteWithTripDataModel Return a CSV report for download or optionally in json format
   * @throws ApiError
   */
  public routesExternal({
    regionId,
    startDate,
    endDate,
    startTimeHour,
    endTimeHour = 23,
    operator,
    vehicleType,
    daysOfWeek,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Start Hour
     */
    startTimeHour?: number
    /**
     * End Hour
     */
    endTimeHour?: number
    /**
     * Operator
     */
    operator?: string | null
    /**
     * Include routes for this vehicle type
     */
    vehicleType?: string | null
    /**
     * Days of the Week to include
     */
    daysOfWeek?: Array<number>
    accept?: string
  }): CancelablePromise<Array<RouteWithTripDataModel>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        start_time_hour: startTimeHour,
        end_time_hour: endTimeHour,
        operator: operator,
        vehicle_type: vehicleType,
        days_of_week: daysOfWeek,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Trips Summary External
   * @returns TripSummaryDataModel Successful Response
   * @throws ApiError
   */
  public tripsSummaryExternal({
    regionId,
    startDate,
    endDate,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
  }): CancelablePromise<TripSummaryDataModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/trips_summary',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Average Operational Vehicles External
   * Get average number of operational (available or in-use) vehicles for a given region and
   * time period. External route, used to populate the vehicle count statistic under the public
   * routes map.
   *
   * This summary statistic is displayed alongisde those returned from the external `/statistics`
   * endpoint above, but is broken out here to separate this database query from the BigQuery
   * logic used above.
   * @returns AverageOperationalVehiclesDataModel Successful Response
   * @throws ApiError
   */
  public averageOperationalVehiclesExternal({
    regionId,
    startDate,
    endDate,
    vehicleTypeFilters,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date in format YYYY-MM-DD
     */
    startDate: string
    /**
     * End date in format YYYY-MM-DD
     */
    endDate: string
    /**
     * Vehicle type(s) to filter by (optional)
     */
    vehicleTypeFilters?: Array<string> | null
  }): CancelablePromise<AverageOperationalVehiclesDataModel> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/routes/average_operational_vehicles',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        vehicle_type_filters: vehicleTypeFilters,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
