/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfigPublicResponse } from '../models/ConfigPublicResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class ConfigService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @deprecated
   * Config Public
   * This is a *publicly* accessible endpoint that returns configuration
   * info used by the frontend.
   * @returns ConfigPublicResponse Successful Response
   * @throws ApiError
   */
  public configPublic(): CancelablePromise<ConfigPublicResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/config/public',
    })
  }
}
