import { FC, useState } from 'react'
import classNames from 'classnames'

import {
  Icon,
  NavbarDropdown,
  NavbarDropdownItemLink,
  NavbarDropdownMenu,
  NavbarDropdownTrigger,
  Text,
} from '@/components/componentLibrary'
import { useGetMobilityLandingPage } from '@/modules/permissions/pageAccessHooks'
import { useGetCurbsLandingPage } from '@/modules/permissions/pageAccessHooks'
import { useCurrentProduct, useCurrentRegion } from '@/modules/urlRouting/hooks'
import { SEGMENTS } from '@/modules/urlRouting/paths'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'
import { useCurrentPermissions } from '@/modules/user/hooks'

import './index.scss'

const ProductSelector: FC = () => {
  const currrentProduct = useCurrentProduct()
  const {
    data: { regionId },
  } = useCurrentRegion()
  const { data: permissions } = useCurrentPermissions()

  const productOptions = [
    {
      disabled: !permissions?.organizationSettings.curbManager,
      product: SEGMENTS.CURB,
      landingPage: useGetCurbsLandingPage(),
      text: 'Curb Manager',
    },
    {
      disabled: !permissions?.organizationSettings.mobilityManager,
      product: SEGMENTS.MOBILITY,
      landingPage: useGetMobilityLandingPage(),
      text: 'Mobility Manager',
    },
  ]

  const [isOpen, setIsOpen] = useState(false)

  return (
    <NavbarDropdown
      className="product-selector"
      disabled={!regionId}
      onClose={() => setIsOpen(false)}
      onOpen={() => setIsOpen(true)}
      trigger={
        <ProductSelectorTrigger
          open={isOpen}
          text={productOptions.find(option => option.product === currrentProduct)?.text}
        />
      }
    >
      <NavbarDropdownMenu>
        {regionId &&
          productOptions.map(({ product, landingPage, ...props }) => (
            <ProductSelectorItem
              active={currrentProduct === product}
              key={product}
              to={generatePathWithRegionId(landingPage, regionId)}
              {...props}
            />
          ))}
      </NavbarDropdownMenu>
    </NavbarDropdown>
  )
}

interface ProductSelectorItemProps {
  active?: boolean
  disabled?: boolean
  to: string
  text: string
}

const ProductSelectorItem: FC<ProductSelectorItemProps> = ({ active, disabled, text, to }) => {
  const isActive = active && !disabled
  return (
    <NavbarDropdownItemLink
      active={isActive}
      disabled={disabled}
      className={classNames('product-selector-item', isActive && 'active')}
      to={to}
    >
      <Text styleType="primary-light">{text}</Text>
      {isActive && (
        <Icon className="active-product-icon" icon="ProductSwitcherCheckmark" color="ocean-1" />
      )}
    </NavbarDropdownItemLink>
  )
}

interface ProductSelectorTriggerProps {
  open?: boolean
  text?: string
}

const ProductSelectorTrigger: FC<ProductSelectorTriggerProps> = ({ open, text }) => {
  return (
    <NavbarDropdownTrigger className="product-selector-trigger" open={open}>
      <Text styleType="title">{text}</Text>
      <div className="icon-background">
        <Icon
          className="product-selector-icon"
          color={open ? 'white' : 'ocean-1'}
          icon="ProductSwitcherControl"
        />
      </div>
    </NavbarDropdownTrigger>
  )
}

export default ProductSelector
