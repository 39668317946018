import { FC, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router'
import {
  AuthError,
  MultiFactorResolver,
  PhoneMultiFactorGenerator,
  PhoneMultiFactorInfo,
  RecaptchaVerifier,
  TotpMultiFactorGenerator,
} from 'firebase/auth'
import { Form, Message } from 'semantic-ui-react'

import {
  fbAuth,
  finishMfaSignInSMS,
  finishMfaSignInTOTP,
  startMfaSignInSMS,
  toHome,
} from '@/modules/authentication'
import { useLocationState } from '@/modules/urlRouting/hooks'

export const LoginFormMFA: FC<{ mfaResolver: MultiFactorResolver }> = ({ mfaResolver }) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { from } = useLocationState()

  const [errorMessage, setErrorMessage] = useState<string>()
  const [loginSuccess, setLoginSuccess] = useState(false)

  const mfaType = mfaResolver.hints[0].factorId

  if (loginSuccess) {
    navigate(toHome(from))
  }

  return (
    <Form>
      <div className="card">
        {errorMessage && (
          <Message negative>
            <Message.Header>{t('login.mfaLoginError', 'Multifactor Error')}</Message.Header>
            <p>{t(`login.mfaLoginError_${errorMessage}`, errorMessage)}</p>
          </Message>
        )}

        {mfaType === TotpMultiFactorGenerator.FACTOR_ID ? (
          <LoginTOTP
            mfaResolver={mfaResolver}
            onSuccess={() => setLoginSuccess(true)}
            onHandleError={(value: string) => setErrorMessage(value)}
          />
        ) : mfaType === PhoneMultiFactorGenerator.FACTOR_ID ? (
          <LoginSMS
            mfaResolver={mfaResolver}
            onSuccess={() => setLoginSuccess(true)}
            onHandleError={(value: string) => setErrorMessage(value)}
          />
        ) : (
          <div>Unsupported MFA type</div>
        )}
      </div>
    </Form>
  )
}

export const LoginTOTP: FC<{
  mfaResolver: MultiFactorResolver
  onSuccess: () => void
  onHandleError: (error: string) => void
}> = ({ mfaResolver, onSuccess, onHandleError }) => {
  const { t } = useTranslation()
  const [mfaCode, setMfaCode] = useState('')

  const submitMfaCode = async () => {
    if (!mfaCode) {
      onHandleError(t(`login.mfaCodeRequired`, 'MFA code required'))
      return
    }

    try {
      await finishMfaSignInTOTP(mfaCode, mfaResolver)
      onSuccess()
    } catch (error: unknown) {
      const authError = error as AuthError
      onHandleError(t(`login.mfaAuthError_${authError.code}`, authError.message))
    }
  }

  return (
    <div>
      <Form.Input
        type="text"
        name="mfaCode"
        value={mfaCode}
        icon="stopwatch"
        iconPosition="left"
        placeholder={t('login.mfaPlaceholderTOTP', 'Enter your multi-factor code')}
        pattern="^[0-9]*$"
        onChange={e => setMfaCode(e.target.value)}
        required
      />
      <Form.Button fluid color="teal" size="small" type="button" onClick={submitMfaCode}>
        {t('common.submit', 'Submit')}
      </Form.Button>
    </div>
  )
}

export const LoginSMS: FC<{
  mfaResolver: MultiFactorResolver
  onSuccess: () => void
  onHandleError: (error: string) => void
}> = ({ mfaResolver, onSuccess, onHandleError }) => {
  const { t } = useTranslation()
  const recaptchaDivRef = useRef<HTMLDivElement>(null)

  const [mfaCode, setMfaCode] = useState('')
  const [mfaVerificationId, setMfaVerificationId] = useState<string | null>()
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>()

  const hint = (mfaResolver.hints[0] as PhoneMultiFactorInfo).phoneNumber

  useEffect(() => {
    const verifier = recaptchaVerifier
      ? recaptchaVerifier
      : recaptchaDivRef.current
        ? new RecaptchaVerifier(fbAuth, recaptchaDivRef.current!, {
            size: 'invisible',
          })
        : null

    if (!verifier) setRecaptchaVerifier(verifier)
    else {
      const mfaSigninSMS = async (verifier: RecaptchaVerifier, resolver: MultiFactorResolver) => {
        const id = await startMfaSignInSMS(verifier, resolver)
        setMfaVerificationId(id)
      }

      mfaSigninSMS(verifier, mfaResolver)
    }
  }, [recaptchaDivRef])

  const submitMfaCode = async () => {
    if (!mfaVerificationId) {
      onHandleError(t(`login.mfaError`, 'Error processing MFA'))
      return
    }

    if (!mfaCode) {
      onHandleError(t(`login.mfaCodeRequired`, 'MFA code required'))
      return
    }

    try {
      await finishMfaSignInSMS(mfaCode, mfaResolver, mfaVerificationId)
      onSuccess()
    } catch (error: unknown) {
      const authError = error as AuthError
      onHandleError(t(`login.mfaAuthError_${authError.code}`, authError.message))
    }
  }

  return (
    <div>
      <div id="recaptcha-container-id" ref={recaptchaDivRef} />
      <p>
        {t('login.mfaCodeSentToPhone', 'A code is being sent to your phone at ')} {hint}
      </p>
      <div>
        <Form.Input
          type="text"
          name="mfaCode"
          value={mfaCode}
          icon="stopwatch"
          iconPosition="left"
          placeholder={t('login.mfaPlaceholderSMS', 'Enter your multi-factor code')}
          pattern="^[0-9]*$"
          onChange={e => setMfaCode(e.target.value)}
          required
        />
        <Form.Button fluid color="teal" size="small" type="button" onClick={submitMfaCode}>
          {t('common.submit', 'Submit')}
        </Form.Button>
      </div>
    </div>
  )
}

export default LoginFormMFA
