import { FC, FormEvent, PropsWithChildren, useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import {
  AuthError,
  AuthErrorCodes,
  multiFactor,
  PhoneMultiFactorGenerator,
  RecaptchaVerifier,
  TotpMultiFactorGenerator,
  TotpSecret,
} from 'firebase/auth'
import { QRCodeCanvas } from 'qrcode.react'
import { Form, Grid, GridColumn, GridRow, Message, Modal } from 'semantic-ui-react'

import {
  getLocalStorageValue,
  LocalStorageKey,
  removeLocalStorageValue,
} from '@/common/utils/localStorage'
import LoadAnimation from '@/components/loadAnimation'
import {
  fbAuth,
  finishEnrollMultiFactorSMS,
  finishEnrollMultiFactorTOTP,
  startEnrollMultiFactorSMS,
  startEnrollMultiFactorTOTP,
  unenrollMultiFactor,
  useAuth,
} from '@/modules/authentication'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const MfaEnrollmentGatekeeper: FC<PropsWithChildren> = ({ children }) => {
  // TODO: can we determine which methods are allowed by the server?

  const { t } = useTranslation()
  const [mfaType, setMfaType] = useState<string | null>(null)
  const { loading, signOut } = useAuth()
  const { data: permissions } = useCurrentPermissions()
  const [showSuccess, setShowSuccess] = useState(false)

  const [enrolled, setEnrolled] = useState(false)
  const [mfaError, setMfaError] = useState<AuthError>()
  const [mfaRequired, setMfaRequired] = useState<boolean | null>(null)

  const wantEnrollMfa = getLocalStorageValue(LocalStorageKey.ENROLL_MFA) === 'true'
  const wantUnenrollMfa = getLocalStorageValue(LocalStorageKey.UNENROLL_MFA) === 'true'

  useEffect(() => {
    const checkEnrollment = async () => {
      if (fbAuth.currentUser) {
        if (wantUnenrollMfa) {
          const unenrolledSuccess = await unenrollMultiFactor()
          setEnrolled(unenrolledSuccess)
        } else {
          const multiFactorUser = multiFactor(fbAuth.currentUser)
          if (multiFactorUser.enrolledFactors.length > 0) setEnrolled(true)
        }
      }
    }

    checkEnrollment()
  }, [fbAuth])

  useEffect(() => {
    if (typeof permissions?.organizationSettings?.mfaRequired === 'boolean')
      setMfaRequired(permissions?.organizationSettings?.mfaRequired)
  }, [permissions])

  if (loading || mfaRequired === null) return <LoadAnimation />

  if (mfaError && mfaError.code === AuthErrorCodes.CREDENTIAL_TOO_OLD_LOGIN_AGAIN) signOut()

  if (enrolled) {
    if (showSuccess) {
      return (
        <Modal
          open
          size="mini"
          closeOnDimmerClick={true}
          content={t('login.mfaAdded', 'Multi-factor device added')}
          onClose={() => setShowSuccess(false)}
        >
          <Modal.Content>
            <p>{t('login.mfaAdded', 'Multi-factor device added')}</p>
          </Modal.Content>
          <Modal.Actions>
            <Form.Button color="teal" onClick={() => setShowSuccess(false)}>
              {t('common.ok', 'OK')}
            </Form.Button>
          </Modal.Actions>
        </Modal>
      )
    }

    return children
  }
  if (!mfaRequired && !wantEnrollMfa) return children

  return (
    <>
      <Modal open size="tiny" closeOnDimmerClick={false}>
        <Modal.Content>
          <Form size="large">
            <div className="card">
              {mfaError && (
                <Message negative>
                  <Message.Header>
                    {t('login.mfaEnrollmentLoginError', 'Multi-factor Error')}
                  </Message.Header>
                  <p>{t(`login.mfaAuthError_${mfaError.code}`, mfaError.message)}</p>
                </Message>
              )}

              {mfaType === null ? (
                <ChooseMFAType onSelectType={(value: string) => setMfaType(value)} />
              ) : mfaType === TotpMultiFactorGenerator.FACTOR_ID ? (
                <EnrollTOTP
                  onSuccess={() => setShowSuccess(true)}
                  onHandleError={(value: AuthError) => setMfaError(value)}
                  onClearError={() => {
                    setMfaError(undefined)
                  }}
                  onEnrolled={(value: boolean) => setEnrolled(value)}
                />
              ) : mfaType === PhoneMultiFactorGenerator.FACTOR_ID ? (
                <EnrollSMS
                  onSuccess={() => setShowSuccess(true)}
                  onHandleError={(value: AuthError) => setMfaError(value)}
                  onClearError={() => {
                    setMfaError(undefined)
                  }}
                  onEnrolled={(value: boolean) => setEnrolled(value)}
                />
              ) : (
                <div>Unsupported MFA type</div>
              )}
            </div>
          </Form>
        </Modal.Content>
      </Modal>
    </>
  )
}

const ChooseMFAType: FC<{
  onSelectType: (type: string) => void
}> = ({ onSelectType }) => {
  const { t } = useTranslation()

  return (
    <>
      <p>{t('login.mfaChooseType', 'Choose your second factor method')}</p>

      <Form.Button
        fluid
        color="teal"
        size="small"
        type="button"
        onClick={() => onSelectType(PhoneMultiFactorGenerator.FACTOR_ID)}
      >
        {t('login.mfaChooseTypeSMS', 'Text Message')}
      </Form.Button>

      <Form.Button
        fluid
        color="teal"
        size="small"
        type="button"
        onClick={() => onSelectType(TotpMultiFactorGenerator.FACTOR_ID)}
      >
        {t('login.mfaChooseTypeTOTP', 'Authenticator App')}
      </Form.Button>
    </>
  )
}

const EnrollTOTP: FC<{
  onSuccess: () => void
  onClearError: () => void
  onHandleError: (error: AuthError) => void
  onEnrolled: (value: boolean) => void
}> = ({ onSuccess, onClearError, onHandleError, onEnrolled }) => {
  const { t } = useTranslation()
  const { signOut } = useAuth()

  const [mfaCode, setMfaCode] = useState('')
  const [totpUri, setTotpUri] = useState<string | null>(null)
  const [totpSecret, setTotpSecret] = useState<TotpSecret | null>(null)
  const [loading, setLoading] = useState(true)

  const submitMfaTOTPCode = async () => {
    try {
      const enrolled = await finishEnrollMultiFactorTOTP(totpSecret, mfaCode)

      onClearError()
      onSuccess()
      onEnrolled(enrolled)
      removeLocalStorageValue(LocalStorageKey.ENROLL_MFA)
    } catch (error: unknown) {
      onHandleError(error as AuthError)
    }
  }

  const setupTotpDevice = async () => {
    try {
      const enrollResponse = await startEnrollMultiFactorTOTP()

      if (enrollResponse) {
        const totpSecret = enrollResponse
        setTotpSecret(totpSecret)

        if (fbAuth.currentUser && fbAuth.currentUser.email) {
          const qrUrl = totpSecret.generateQrCodeUrl(fbAuth.currentUser.email, 'Populus Terminal')

          setTotpUri(qrUrl)
        } else {
          throw new Error('user email not set')
        }
      }
      setLoading(false)
      onClearError()
    } catch (error: unknown) {
      onHandleError(error as AuthError)
    }
  }

  useEffect(() => {
    setupTotpDevice()
  }, [])

  if (loading) return <LoadAnimation />

  if (totpSecret === null) return <div>Error during device setup</div>

  return (
    <Grid>
      <GridRow columns={1}>
        <GridColumn>
          <div style={{ textAlign: 'center' }}>
            <p>{t('login.mfaEnrollmentCodeSent', 'Register your TOTP device')}</p>

            <QRCodeCanvas value={totpUri || 'unknown'} size={256} />
            <p style={{ margin: '20px' }}>Key: {totpSecret.secretKey}</p>
          </div>

          <Form.Input
            type="text"
            name="mfaCode"
            value={mfaCode}
            icon="stopwatch"
            iconPosition="left"
            placeholder={t('login.mfaPlaceholder', 'Enter your one-time code')}
            pattern="^[0-9]*$"
            onChange={e => setMfaCode(e.target.value)}
            required
          />
        </GridColumn>
      </GridRow>

      <GridRow columns={2}>
        <GridColumn>
          <Form.Button fluid color="grey" size="small" type="button" onClick={e => signOut()}>
            {t('common.cancel', 'Cancel')}
          </Form.Button>
        </GridColumn>
        <GridColumn>
          <Form.Button fluid color="teal" size="small" type="button" onClick={submitMfaTOTPCode}>
            {t('common.submit', 'Submit')}
          </Form.Button>
        </GridColumn>
      </GridRow>
    </Grid>
  )
}

const EnrollSMS: FC<{
  onSuccess: () => void
  onClearError: () => void
  onHandleError: (error: AuthError) => void
  onEnrolled: (value: boolean) => void
}> = ({ onSuccess, onClearError, onHandleError, onEnrolled }) => {
  const { t } = useTranslation()
  const recaptchaDivRef = useRef<HTMLDivElement>(null)
  const { signOut } = useAuth()

  const [mfaCode, setMfaCode] = useState('')
  const [phoneNumber, setPhoneNumber] = useState<string>('')
  const [mfaVerificationId, setMfaVerificationId] = useState<string | null>(null)
  const [recaptchaVerifier, setRecaptchaVerifier] = useState<RecaptchaVerifier | null>()

  const submitPhoneNumber = async (e: FormEvent) => {
    e.preventDefault()

    let verifier = recaptchaVerifier

    if (!verifier) {
      verifier = new RecaptchaVerifier(fbAuth, recaptchaDivRef.current!, {
        size: 'invisible',
      })
      setRecaptchaVerifier(verifier)
    }

    try {
      const verificationId = await startEnrollMultiFactorSMS(verifier, phoneNumber)
      setMfaVerificationId(verificationId)
      onClearError()
    } catch (error: unknown) {
      onHandleError(error as AuthError)
    }
  }

  const submitMfaSMSCode = async () => {
    try {
      const enrolled = await finishEnrollMultiFactorSMS(mfaVerificationId, mfaCode)
      onClearError()
      onSuccess()
      onEnrolled(enrolled)
      removeLocalStorageValue(LocalStorageKey.ENROLL_MFA)
    } catch (error: unknown) {
      onHandleError(error as AuthError)
    }
  }

  return (
    <>
      <div id="recaptcha-container-id" ref={recaptchaDivRef} />
      {mfaVerificationId ? (
        <>
          <p>{t('login.mfaEnrollmentCodeSent', 'A code is being sent to your phone')}</p>
          <Form.Input
            type="text"
            name="mfaCode"
            value={mfaCode}
            icon="stopwatch"
            iconPosition="left"
            placeholder={t('login.mfaPlaceholder', 'Enter your multi-factor code')}
            pattern="^[0-9]*$"
            onChange={e => setMfaCode(e.target.value)}
            required
          />

          <Form.Button fluid color="teal" size="small" type="button" onClick={submitMfaSMSCode}>
            {t('common.submit', 'Submit')}
          </Form.Button>
        </>
      ) : (
        <Grid>
          <GridRow columns={1}>
            <GridColumn>
              <p>{t('login.mfaAddDevice', 'Add a multi-factor device')}</p>

              <Form.Input
                type="text"
                name="phoneNumber"
                value={phoneNumber}
                icon="phone"
                iconPosition="left"
                placeholder={t(
                  'login.phonePlaceHolder',
                  'Enter your phone number (including country code)'
                )}
                onChange={e => setPhoneNumber(e.target.value)}
                required
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={2}>
            <GridColumn>
              <Form.Button fluid color="grey" size="small" type="button" onClick={e => signOut()}>
                {t('common.cancel', 'Cancel')}
              </Form.Button>
            </GridColumn>
            <GridColumn>
              <Form.Button
                fluid
                color="teal"
                size="small"
                type="button"
                onClick={submitPhoneNumber}
              >
                {t('common.submit', 'Submit')}
              </Form.Button>
            </GridColumn>
          </GridRow>
        </Grid>
      )}
    </>
  )
}

export default MfaEnrollmentGatekeeper
