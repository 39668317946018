/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregationMode } from '../models/AggregationMode'
import type { TimePeriodType } from '../models/TimePeriodType'
import type { VehicleCountsByShape } from '../models/VehicleCountsByShape'
import type { VehiclesByHourResponse } from '../models/VehiclesByHourResponse'
import type { VehiclesByTimePeriodResponse } from '../models/VehiclesByTimePeriodResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class VehiclesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Vehicles Non Operational Summary
   * @returns any Successful Response
   * @throws ApiError
   */
  public vehiclesNonOperationalSummary({
    regionId,
    start,
    end,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    start: string
    end: string
    accept?: string
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/vehicles/non_operational_summary',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        start: start,
        end: end,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Vehicles Non Operational Events
   * @returns any Successful Response
   * @throws ApiError
   */
  public vehiclesNonOperationalEvents({
    regionId,
    start,
    end,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    start: string
    end: string
    accept?: string
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/vehicles/non_operational_events',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        start: start,
        end: end,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Vehicles By Hour
   * every hour in range is included (missing ones are filled in with 0), sorted in ascending order
   * @returns VehiclesByHourResponse Successful Response
   * @throws ApiError
   */
  public vehiclesByHour({
    regionId,
    start,
    end,
    fields,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    start: string
    end: string
    fields?: Array<string>
    accept?: string
  }): CancelablePromise<VehiclesByHourResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/vehicles/by_hour',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        fields: fields,
        start: start,
        end: end,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Vehicles By Time Period
   * Aggregate vehicles by time period and mode. Missing data points are filled
   * in with 0. When time period is week, weeks are defined as per ISO week date,
   * and when time period is month, months are defined as 7 character ISO date strings,
   * like '2019-08'.
   * @returns VehiclesByTimePeriodResponse Successful Response
   * @throws ApiError
   */
  public getVehiclesByTimePeriod({
    regionId,
    startDate,
    endDate,
    timePeriodType,
    mode,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date filter
     */
    startDate: string
    /**
     * End date filter
     */
    endDate: string
    /**
     * Time period for aggregation
     */
    timePeriodType: TimePeriodType
    /**
     * Value category by which to aggregate the data
     */
    mode: AggregationMode
  }): CancelablePromise<Array<VehiclesByTimePeriodResponse>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/vehicles/by_time_period',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        time_period_type: timePeriodType,
        mode: mode,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Vehicle Counts By Shape
   * Compute average hourly vehicle counts by geography metrics.
   * A note on dates: this endpoint offers daily resolution only.
   * start_date and end_date are truncated to their day component and
   * each is treated inclusively, as if the client had asked for the entirety
   * of the specified day. To get only one day of data the client should
   * specify start_date and end_date as the same value.
   * @returns VehicleCountsByShape Successful Response
   * @throws ApiError
   */
  public getVehicleCountsByShape({
    regionId,
    shapeLayerUuid,
    startDate,
    endDate,
    operators,
    vehicleType,
    startTimeHour,
    endTimeHour,
    daysOfWeek,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Layer identifier
     */
    shapeLayerUuid: string
    /**
     * Start date filter
     */
    startDate: string
    /**
     * End date filter
     */
    endDate: string
    /**
     * Operators filter
     */
    operators?: Array<string> | null
    /**
     * Vehicle types filter
     */
    vehicleType?: string | null
    /**
     * Start time filter
     */
    startTimeHour?: number | null
    /**
     * End time filter
     */
    endTimeHour?: number | null
    /**
     * Days of week filter
     */
    daysOfWeek?: Array<number> | null
  }): CancelablePromise<Array<VehicleCountsByShape>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/layers/{shape_layer_uuid}/vehicles/counts_by_shape',
      path: {
        region_id: regionId,
        shape_layer_uuid: shapeLayerUuid,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        operators: operators,
        vehicle_type: vehicleType,
        start_time_hour: startTimeHour,
        end_time_hour: endTimeHour,
        days_of_week: daysOfWeek,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
