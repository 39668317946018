/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AggregationMode } from '../models/AggregationMode'
import type { TimePeriodType } from '../models/TimePeriodType'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class TripsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Trips By Time Period
   * Aggregate trips by time period and mode. For the response, keys are the mode
   * identifier, eg 'bird' for operators, 'scooter' for vehicle_type and 'bird:scooter'
   * for vehicle_type_and_operators. Values are a list of counts, one for each
   * time period. There is also a key for _totals_. Missing data points are filled
   * in with 0. When time period is week, weeks are defined as per ISO week date,
   * and when time period is month, months are defined as 7 character ISO date strings,
   * like '2019-08'.
   *
   * Example response:
   * {
   * "bird": [
   * {
   * "count": 12,
   * "hour": "2019-08-01T00:00"
   * }
   * ],
   * "_totals_": [
   * {
   * "count": 123,
   * "hour": "2019-08-01T00:00"
   * }
   * ]
   * }
   * @returns any Successful Response
   * @throws ApiError
   */
  public getTripsByTimePeriod({
    regionId,
    startDate,
    endDate,
    timePeriodType,
    mode,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Start date filter
     */
    startDate: string
    /**
     * End date filter
     */
    endDate: string
    /**
     * Time period for aggregation
     */
    timePeriodType: TimePeriodType
    /**
     * Value category by which to aggregate the data
     */
    mode: AggregationMode
  }): CancelablePromise<Record<string, Array<Record<string, string | number>>>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/trips/by_time_period',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        time_period_type: timePeriodType,
        mode: mode,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
