import { includes, map } from 'lodash'
import { action, makeObservable, observable } from 'mobx'

import { formatText } from '@/common/utils/text'
import { VehicleTypeOption } from '@/common/utils/types'
import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import i18n from '@/modules/i18n/i18n'
import { mdsPublicJson } from '@/modules/initialization/utils'
import { monitorBreadcrumb } from '@/modules/monitoring'

class AppUIStore {
  region: RegionResponse | undefined

  constructor() {
    makeObservable(this, {
      region: observable,
      setRegion: action,
      clearRegion: action,
    })
  }

  setRegion(region: RegionResponse) {
    this.region = region
    monitorBreadcrumb('appUIStore: region set', region)
  }

  clearRegion() {
    this.region = undefined
    monitorBreadcrumb('appUIStore: region cleared')
  }

  get distanceUnits() {
    if (this.region?.isMetric) return i18n.t('common.kilometer_abbr', 'km')
    return i18n.t('common.miles_abbr', 'mi')
  }
  get isMDSRegion() {
    const r = map(mdsPublicJson, 'region')
    return includes(r, this.region!.regionId)
  }

  getVehicleTypesMap(textOrValueKey: 'text' | 'value' = 'value') {
    const { t } = i18n
    const vehicleTypeOptions = [
      { value: 'bicycle', text: t('common.vehicleType_bicycle', 'Electric Bike') },
      { value: 'pedal_bicycle', text: t('common.vehicleType_pedal_bicycle', 'Pedal Bicycle') },
      { value: 'car', text: t('common.vehicleType_car', 'Car') },
      { value: 'moped', text: t('common.vehicleType_moped', 'Moped') },
      { value: 'scooter', text: t('common.vehicleType_scooter', 'Scooter') },
      {
        value: 'scooter_standing',
        text: t('common.vehicleType_scooter_standing', 'Scooter Standing'),
      },
      { value: 'delivery_robot', text: t('common.vehicleType_delivery_robot', 'Delivery Robot') },
      { value: 'scooter_seated', text: t('common.vehicleType_scooter_seated', 'Scooter Seated') },
      { value: 'other', text: t('common.vehicleType_other', 'Other') },
    ]

    const vehicleTypes: any = {}
    vehicleTypeOptions.forEach(v => {
      vehicleTypes[textOrValueKey === 'text' ? v.text : v.value] = v.text
    })

    return vehicleTypes
  }
}

/**
 * @deprecated Please use the useCurrentRegion or useRegion hooks
 */
export default new AppUIStore()
