/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreatePolicyBody } from '../models/CreatePolicyBody'
import type { CreatePolicyResponse } from '../models/CreatePolicyResponse'
import type { CreatePolicyRuleBody } from '../models/CreatePolicyRuleBody'
import type { CreatePolicyRuleResponse } from '../models/CreatePolicyRuleResponse'
import type { CreateRateBody } from '../models/CreateRateBody'
import type { CreateRateResponse } from '../models/CreateRateResponse'
import type { CreateScheduleBody } from '../models/CreateScheduleBody'
import type { CreateScheduleResponse } from '../models/CreateScheduleResponse'
import type { Page_PolicyRevenueResponse_ } from '../models/Page_PolicyRevenueResponse_'
import type { Page_RateResponse_ } from '../models/Page_RateResponse_'
import type { Page1K_PolicyV2Response_ } from '../models/Page1K_PolicyV2Response_'
import type { Page1K_PolicyV2RuleResponse_ } from '../models/Page1K_PolicyV2RuleResponse_'
import type { Page1K_Schedule_ } from '../models/Page1K_Schedule_'
import type { PolicyCategoryEnum } from '../models/PolicyCategoryEnum'
import type { PolicyEvaluationMetricsByShape } from '../models/PolicyEvaluationMetricsByShape'
import type { PolicyEvaluationMetricsByTime } from '../models/PolicyEvaluationMetricsByTime'
import type { PolicyFiltersResponse } from '../models/PolicyFiltersResponse'
import type { PolicyType } from '../models/PolicyType'
import type { PolicyTypes } from '../models/PolicyTypes'
import type { PolicyV2Response } from '../models/PolicyV2Response'
import type { PolicyV2RuleResponse } from '../models/PolicyV2RuleResponse'
import type { RateResponse } from '../models/RateResponse'
import type { ReportsRevenueItem } from '../models/ReportsRevenueItem'
import type { Schedule } from '../models/Schedule'
import type { TotalRevenueResponse } from '../models/TotalRevenueResponse'
import type { UpdatePolicyBody } from '../models/UpdatePolicyBody'
import type { UpdatePolicyRule } from '../models/UpdatePolicyRule'
import type { UpdateRateBody } from '../models/UpdateRateBody'
import type { UpdateScheduleBody } from '../models/UpdateScheduleBody'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class PoliciesV2Service {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get Metrics By Time
   * Policy Evaluation Metrics by Time
   * Get metrics from policy events and policy evaluation based on time
   *
   * In order to have the metrics to have consistent units, we need to constrain the policy type(s) or
   * perhaps at minimum, the rule units. However, the rule units may mean different things. Seconds
   * could mean dwell time for parking, or trip duration, which would be weird to combine.
   *
   * Additionally, even if the policies are the same, some might only be used for curbs, while others
   * would only be used for mobility. This could be determined by a set of vehicle types that curbs
   * pre-filters, or a tag on policies that represents it belonging to curbs vs mobility.
   *
   * You could imagine that we may want to compare revenue across disparate policies types that have
   * separate rule units. This might occur more for mobility, since curbs largely deals with
   * parking events. In this case, we probably wouldn't want to calculate event_value data (unless)
   * we split it by policy type, but we may want to calculate total revenue. To accomplish this, the
   * client could request this endpoint for each policy type and then sum all the revenue from each
   * policy type on their end, or, the backend could accept any combo of filters and split out policy
   * type event_value totals per policy type, while combining the total revenue for all policies.
   *
   * An alternative query structure could be to separate revenue and event endpoints. The revenue
   * endpoint would simply take all evaluation records and calculate the pro-rated revenue for each
   * time period. The event based endpoint would take similar filters as the revenue endpoint, but
   * would only work for one activity type. Multiple activity types would require multiple calls.
   * The event count and event values would be totalled. Do evaluation record totals match event
   * totals or are they just part of the total that matched?
   * @returns PolicyEvaluationMetricsByTime Successful Response
   * @throws ApiError
   */
  public getMetricsByTime({
    regionId,
    startDate,
    endDate,
    timePeriodType = 'date',
    policyIds,
    operators,
    purposes,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter evaluation records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter evaluation records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
    /**
     * Filter evaluation records that started on or before the provided ISO end date (inclusive)
     */
    timePeriodType?: 'full' | 'date' | 'hour_of_day' | 'day_of_week' | 'month_of_year'
    /**
     * Filter for policy ids
     */
    policyIds?: Array<string> | null
    /**
     * Filter for operators
     */
    operators?: Array<string> | null
    /**
     * Filter for purposes
     */
    purposes?: Array<string> | null
  }): CancelablePromise<PolicyEvaluationMetricsByTime> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/metrics/by_time',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        time_period_type: timePeriodType,
        policy_ids: policyIds,
        operators: operators,
        purposes: purposes,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Metrics By Shape
   * Get metrics from policy evaluation records grouped by shape_uuid. The shapes that are used
   * are determined by the passed in layer_slug.
   *
   * Metrics are determined based on policy evaluation values. Therefore, when grouping by a layer
   * that is different than the policies original layer, you will not get metrics for all the events
   * in the queried layer, instead, you will get metrics from events that matched the policy's layer
   * and schedule.
   * @returns PolicyEvaluationMetricsByShape Successful Response
   * @throws ApiError
   */
  public getMetricsByShape({
    regionId,
    startDate,
    endDate,
    shapeLayerUuid,
    policyUuids,
    operators,
    purposes,
    startTime,
    endTime,
    daysOfWeek,
    includeEmptyShapes = false,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Filter evaluation records that end after the provided ISO start date (inclusive)
     */
    startDate: string
    /**
     * Filter evaluation records that started on or before the provided ISO end date (inclusive)
     */
    endDate: string
    /**
     * Group evaluation records by shape_uuids from this layer
     */
    shapeLayerUuid: string
    /**
     * Filter for policy uuids
     */
    policyUuids?: Array<string> | null
    /**
     * Filter for operators
     */
    operators?: Array<string> | null
    /**
     * Filter for purposes
     */
    purposes?: Array<string> | null
    /**
     * Filter metrics of evaluation records by the start_time in isoformat (inclusive). Only hour increments are supported currently. If an evaluation record started before the start_time but ended after it, the portion of the evaluation record after the start_time will be included in the metric.
     */
    startTime?: string | null
    /**
     * Filter metrics of evaluation records by the end_time in format isoformat (exclusive). Only hour increments are supported currently. If an evaluation record started before the end_time but ended after it, the portion of the evaluation record before the end_time will be included in the metric.
     */
    endTime?: string | null
    /**
     * Filter metrics of evaluation records by the days_of_the_week using isodow. If an evaluation record started on an excluded day of the week but overlaps an included day of the week, the portion of the evaluation record on the included day of the week will be included in the metric.
     */
    daysOfWeek?: Array<number> | null
    /**
     * Includes shapes in the selected policies that have no events if True. Default False
     */
    includeEmptyShapes?: boolean
  }): CancelablePromise<PolicyEvaluationMetricsByShape> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/metrics/by_shape',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
        shape_layer_uuid: shapeLayerUuid,
        policy_uuids: policyUuids,
        operators: operators,
        purposes: purposes,
        start_time: startTime,
        end_time: endTime,
        days_of_week: daysOfWeek,
        include_empty_shapes: includeEmptyShapes,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Revenue
   * @returns Page_PolicyRevenueResponse_ Successful Response
   * @throws ApiError
   */
  public getPolicyRevenue({
    regionId,
    startDate,
    endDate,
    page = 1,
    size = 50,
    orderBy,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    startDate: string
    endDate: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
    orderBy?: string | null
    accept?: string
  }): CancelablePromise<Page_PolicyRevenueResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/revenue',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        page: page,
        size: size,
        start_date: startDate,
        end_date: endDate,
        order_by: orderBy,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Total Revenue
   * @returns TotalRevenueResponse Successful Response
   * @throws ApiError
   */
  public getTotalRevenue({
    regionId,
    startDate,
    endDate,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    startDate: string
    endDate: string
  }): CancelablePromise<TotalRevenueResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/revenue/totals',
      path: {
        region_id: regionId,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Revenue Full
   * @returns ReportsRevenueItem Return a CSV report for download or optionally in json format
   * @throws ApiError
   */
  public getPolicyRevenueFull({
    regionId,
    startDate,
    endDate,
    accept = 'application/json',
  }: {
    /**
     * Region identifier
     */
    regionId: string
    startDate: string
    endDate: string
    accept?: string
  }): CancelablePromise<Array<ReportsRevenueItem>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/revenue/full',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      query: {
        start_date: startDate,
        end_date: endDate,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Types
   * Returns a list of policy types, sorted by display priority.
   *
   * Defaults to returning all policy types - setting 'include_all_types' to false
   * instead returns only the types relevant to the current region.
   * @returns PolicyTypes Successful Response
   * @throws ApiError
   */
  public getPolicyTypes({
    regionId,
    includeAllTypes = true,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * When true include all possible policy types, when false include only those policy types implemented in the region
     */
    includeAllTypes?: boolean
  }): CancelablePromise<Array<PolicyTypes>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/types',
      path: {
        region_id: regionId,
      },
      query: {
        include_all_types: includeAllTypes,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Filter Options
   * @returns PolicyFiltersResponse Successful Response
   * @throws ApiError
   */
  public getPolicyFilterOptions({
    regionId,
  }: {
    /**
     * Region identifier
     */
    regionId: string
  }): CancelablePromise<PolicyFiltersResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/filter_options',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policies
   * List all policies for the current region.
   * @returns Page1K_PolicyV2Response_ Successful Response
   * @throws ApiError
   */
  public getPolicies({
    regionId,
    userClassContains,
    id,
    type,
    vehicleTypes,
    category,
    shapeLayerId,
    search,
    startDateLt,
    startDateGte,
    endDateLt,
    endDateGte,
    orderBy,
    page = 1,
    size = 300,
  }: {
    regionId: string | null
    userClassContains?: Array<string> | null
    id?: string | null
    type?: PolicyType | null
    vehicleTypes?: string | null
    category?: PolicyCategoryEnum | null
    shapeLayerId?: string | null
    search?: string | null
    startDateLt?: string | null
    startDateGte?: string | null
    endDateLt?: string | null
    endDateGte?: string | null
    orderBy?: string | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page1K_PolicyV2Response_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2',
      path: {
        region_id: regionId,
      },
      query: {
        user_class__contains: userClassContains,
        id: id,
        type: type,
        vehicle_types: vehicleTypes,
        category: category,
        shape_layer_id: shapeLayerId,
        search: search,
        start_date__lt: startDateLt,
        start_date__gte: startDateGte,
        end_date__lt: endDateLt,
        end_date__gte: endDateGte,
        order_by: orderBy,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Create Policy
   * @returns CreatePolicyResponse Successful Response
   * @throws ApiError
   */
  public createPolicy({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: CreatePolicyBody
  }): CancelablePromise<CreatePolicyResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/policies_v2',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policies Vector Tiles
   * Returns all filtered policies in MVT form
   * @returns string Successful Response
   * @throws ApiError
   */
  public getPoliciesVectorTiles({
    regionId,
    z,
    x,
    y,
    ifModifiedSince,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    z: number
    x: number
    y: number
    ifModifiedSince?: string | null
  }): CancelablePromise<string> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/vector/{z}/{x}/{y}',
      path: {
        region_id: regionId,
        z: z,
        x: x,
        y: y,
      },
      query: {
        if_modified_since: ifModifiedSince,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Policy
   * Patch an existing Policy from a UUID.
   * @returns PolicyV2Response Successful Response
   * @throws ApiError
   */
  public updatePolicy({
    regionId,
    id,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy identifier
     */
    id: string
    requestBody: UpdatePolicyBody
  }): CancelablePromise<PolicyV2Response> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/regions/{region_id}/policies_v2/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy By Id
   * Get a single policy from a UUID.
   * @returns PolicyV2Response Successful Response
   * @throws ApiError
   */
  public getPolicyById({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy identifier
     */
    id: string
  }): CancelablePromise<PolicyV2Response> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/policies_v2/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get All Rates
   * List all non-deleted rates for the current region.
   * @returns Page_RateResponse_ Successful Response
   * @throws ApiError
   */
  public getAllRates({
    regionId,
    rateType,
    rateGte,
    rateLt,
    orderBy,
    page = 1,
    size = 50,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    rateType?: string | null
    rateGte?: number | null
    rateLt?: number | null
    orderBy?: string | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page_RateResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rates',
      path: {
        region_id: regionId,
      },
      query: {
        rate_type: rateType,
        rate_gte: rateGte,
        rate_lt: rateLt,
        order_by: orderBy,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Create Rate
   * @returns CreateRateResponse Successful Response
   * @throws ApiError
   */
  public createRate({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: CreateRateBody
  }): CancelablePromise<CreateRateResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/rates',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Rate
   * Get the rate by UUID.
   * @returns RateResponse Successful Response
   * @throws ApiError
   */
  public getRate({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Rate identifier
     */
    id: string
  }): CancelablePromise<RateResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rates/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Rate
   * Update the rate by uuid.
   * @returns RateResponse Successful Response
   * @throws ApiError
   */
  public updateRate({
    regionId,
    id,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Rate identifier
     */
    id: string
    requestBody: UpdateRateBody
  }): CancelablePromise<RateResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/regions/{region_id}/rates/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Delete Rate
   * Delete the rate by uuid.
   * @returns void
   * @throws ApiError
   */
  public deleteRate({
    regionId,
    id,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Rate identifier
     */
    id: string
    requestBody: CreateRateBody
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/regions/{region_id}/rates/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Rules
   * List all policy v2 rules for the current region.
   * @returns Page1K_PolicyV2RuleResponse_ Successful Response
   * @throws ApiError
   */
  public getPolicyRules({
    regionId,
    unit,
    search,
    maximum,
    orderBy,
    policyId,
    policyType,
    policyVehicleTypes,
    policyCategory,
    policyRegionId,
    policyShapeLayerId,
    policySearch,
    policyStartDateLt,
    policyStartDateGte,
    policyEndDateLt,
    policyEndDateGte,
    policyOrderBy,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    unit?: string | null
    search?: string | null
    maximum?: number | null
    orderBy?: string | null
    policyId?: string | null
    policyType?: PolicyType | null
    policyVehicleTypes?: string | null
    policyCategory?: PolicyCategoryEnum | null
    policyRegionId?: string | null
    policyShapeLayerId?: string | null
    policySearch?: string | null
    policyStartDateLt?: string | null
    policyStartDateGte?: string | null
    policyEndDateLt?: string | null
    policyEndDateGte?: string | null
    policyOrderBy?: string | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page1K_PolicyV2RuleResponse_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rules',
      path: {
        region_id: regionId,
      },
      query: {
        unit: unit,
        search: search,
        maximum: maximum,
        order_by: orderBy,
        policy__id: policyId,
        policy__type: policyType,
        policy__vehicle_types: policyVehicleTypes,
        policy__category: policyCategory,
        policy__region_id: policyRegionId,
        policy__shape_layer_id: policyShapeLayerId,
        policy__search: policySearch,
        policy__start_date__lt: policyStartDateLt,
        policy__start_date__gte: policyStartDateGte,
        policy__end_date__lt: policyEndDateLt,
        policy__end_date__gte: policyEndDateGte,
        policy__order_by: policyOrderBy,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Create Policy Rule
   * @returns CreatePolicyRuleResponse Successful Response
   * @throws ApiError
   */
  public createPolicyRule({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: CreatePolicyRuleBody
  }): CancelablePromise<CreatePolicyRuleResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/rules',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Policy Rule
   * Patch an existing Policy Rule from a UUID.
   * @returns PolicyV2RuleResponse Successful Response
   * @throws ApiError
   */
  public updatePolicyRule({
    regionId,
    id,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy rule identifier
     */
    id: string
    requestBody: UpdatePolicyRule
  }): CancelablePromise<PolicyV2RuleResponse> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/regions/{region_id}/rules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Policy Rule
   * Get a single policy v2 rule from a UUID.
   * @returns PolicyV2RuleResponse Successful Response
   * @throws ApiError
   */
  public getPolicyRule({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Policy rule identifier
     */
    id: string
  }): CancelablePromise<PolicyV2RuleResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/rules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Schedules
   * List all schedules for the current region.
   * @returns Page1K_Schedule_ Successful Response
   * @throws ApiError
   */
  public getSchedules({
    regionId,
    daysOfWeekContains,
    daysOfMonthContains,
    monthsContains,
    scheduleIdsContains,
    scheduleType,
    startTimeGte,
    startTimeLt,
    endTimeGte,
    endTimeLt,
    orderBy,
    page = 1,
    size = 300,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    daysOfWeekContains?: Array<number> | null
    daysOfMonthContains?: Array<number> | null
    monthsContains?: Array<number> | null
    scheduleIdsContains?: Array<string> | null
    scheduleType?: string | null
    startTimeGte?: string | null
    startTimeLt?: string | null
    endTimeGte?: string | null
    endTimeLt?: string | null
    orderBy?: string | null
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page1K_Schedule_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/schedules',
      path: {
        region_id: regionId,
      },
      query: {
        days_of_week__contains: daysOfWeekContains,
        days_of_month__contains: daysOfMonthContains,
        months__contains: monthsContains,
        schedule_ids__contains: scheduleIdsContains,
        schedule_type: scheduleType,
        start_time__gte: startTimeGte,
        start_time__lt: startTimeLt,
        end_time__gte: endTimeGte,
        end_time__lt: endTimeLt,
        order_by: orderBy,
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Create Schedule
   * @returns CreateScheduleResponse Successful Response
   * @throws ApiError
   */
  public createSchedule({
    regionId,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    requestBody: CreateScheduleBody
  }): CancelablePromise<CreateScheduleResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/regions/{region_id}/schedules',
      path: {
        region_id: regionId,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Get Schedule
   * Get schedule from UUID.
   * @returns Schedule Successful Response
   * @throws ApiError
   */
  public getSchedule({
    regionId,
    id,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    id: string
  }): CancelablePromise<Schedule> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/schedules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Update Schedule
   * @returns Schedule Successful Response
   * @throws ApiError
   */
  public updateSchedule({
    regionId,
    id,
    requestBody,
  }: {
    /**
     * Region identifier
     */
    regionId: string
    /**
     * Schedule identifier
     */
    id: string
    requestBody: UpdateScheduleBody
  }): CancelablePromise<Schedule> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/regions/{region_id}/schedules/{id}',
      path: {
        region_id: regionId,
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
