import { RegionResponse } from '@/modules/api/openapi/models/RegionResponse'
import { MAP_CONSTANTS } from '@/modules/permissions/constants'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PATHS } from '@/modules/urlRouting/paths'
import { useCurrentPermissions } from '@/modules/user/hooks'

export const useMobilityAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.mobilityManager
}

export const useMobilityActivitySummaryAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()
  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.home &&
    region?.hasMdsData
  )
}

export const useMobilityDistributionMapAccess = () =>
  useHasMapPermissions(MAP_CONSTANTS.DISTRIBUTION)

export const useMobilityGeographiesMapAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  return useHasMapPermissions('shapes')
}

export const useMobilityInvoicingAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.feeSummary
  )
}

export const useMobilityIssuesMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.ISSUE)

export const useMobilityLiveMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.LIVE)

export const useMobilityLiveMapMobileAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return useMobilityLiveMapAccess() && !!permissions?.organizationSettings.liveMapMobile
}

export const useMobilityMDSHealthAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.mobilityManager
}

export const useMobilityOperatorDropOffsMapAccess = () =>
  useHasMapPermissions(MAP_CONSTANTS.OPERATOR_DROP_OFFS)

export const useMobilityParkingMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.PARKING)

export const useMobilityPoliciesAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()
  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.policiesLibrary &&
    !!region?.allowedMaps.includes('policy')
  )
}

export const useMobilityPolicyComplianceAccess = () => {
  const { data: permissions } = useCurrentPermissions()

  return (
    !!permissions?.organizationSettings.mobilityManager &&
    !!permissions?.organizationSettings.complianceReporting
  )
}

export const useMobilityPolicyComplianceDetailsAccess = () => {
  const { data: permissions } = useCurrentPermissions()

  return !!permissions?.organizationSettings.complianceDetails
}

export const useMobilityReportingExportBikeshareStationReportAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.bikeshareStationReport
}

export const useMobilityReportingExportDataAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()
  return !!(region?.hasMonthlyZip && permissions?.organizationSettings.operators === null)
}

export const useMobilityReportingExportReportsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.export
}

export const useMobilityReportingMobilityMetricsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  return !!permissions?.organizationSettings.mobilityMetrics
}

export const useMobilityReportingParkingAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  if (!permissions.organizationSettings.mobilityDashboards) return true
  return !!permissions.organizationSettings.mobilityDashboards.includes('micromobility_parking')
}

export const useMobilityReportingTimeDistanceAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()
  if (!permissions || !region?.hasMdsData) return false
  if (!permissions.organizationSettings.mobilityManager) return false
  if (!permissions.organizationSettings.mobilityDashboards) return true
  return !!permissions.organizationSettings.mobilityDashboards.includes('micromobility_use')
}

export const useMobilityReportingTripCountsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  if (!permissions) return false
  return (
    permissions.organizationSettings.mobilityManager &&
    !!permissions.organizationSettings.vehicleAndTripCounts
  )
}

export const useMobilityRoutesMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.ROUTES)

export const useMobilityTripsMapAccess = () => useHasMapPermissions(MAP_CONSTANTS.TRIPS)

export const useCurbParkingInsightsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbParkingInsights
}

export const useCurbParkingTransactionsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbTransactionsTable
}

export const useCurbTransactionsTableAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbTransactionsTable
}

export const useCurbPricingEngineAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbPricingEngine
}

export const useCurbOccupancyAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbOccupancy
}

export const useCurbRegulationsLibraryAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbRegulationsLibrary
}

export const useCurbMetricsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbMetricsPage
}

export const useCurbGeographiesMapAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbManager
}

export const useCurbFeesAndRevenueAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbFeesAndRevenue
}

export const useCurbActivityAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbPerformance
}

export const useCurbRegulationsAccess = () => {
  const { data: permissions } = useCurrentPermissions()
  return !!permissions?.organizationSettings.curbRegulations
}
export const useGetCurbsLandingPage = () => {
  const hasCurbMetricsAccess = useCurbMetricsAccess()
  const hasCurbActivityAccess = useCurbActivityAccess()
  const hasCurbRegulationsAccess = useCurbRegulationsAccess()
  const hasCurbFeesAndRevenuePageAccess = useCurbFeesAndRevenueAccess()
  const hasCurbParkingInsightsAccess = useCurbParkingInsightsAccess()
  const hasCurbTransactionsTableAccess = useCurbTransactionsTableAccess()
  const hasCurbRegulationsLibraryAccess = useCurbRegulationsLibraryAccess()

  if (hasCurbMetricsAccess) return PATHS.CURB.MANAGEMENT.METRICS
  if (hasCurbActivityAccess) return PATHS.CURB.MANAGEMENT.ACTIVITY
  if (hasCurbParkingInsightsAccess) return PATHS.CURB.PARKING_INSIGHTS.REVENUE
  if (hasCurbTransactionsTableAccess) return PATHS.CURB.PARKING_INSIGHTS.TRANSACTIONS
  if (hasCurbRegulationsLibraryAccess) return PATHS.CURB.REGULATIONS.LIBRARY
  if (hasCurbRegulationsAccess) return PATHS.CURB.REGULATIONS.MAP
  if (hasCurbFeesAndRevenuePageAccess) return PATHS.CURB.REVENUE.FEES_AND_REVENUE

  return PATHS.ROOT
}

export const useGetMobilityLandingPage = () => PATHS.MOBILITY.MAPS.LIVE

export const useHasMapPermissions = (mapName: string): boolean => {
  const { data: permissions } = useCurrentPermissions()
  const { data: region } = useCurrentRegion<RegionResponse>()

  if (mapName === MAP_CONSTANTS.ROUTES && !region?.hasMdsData) return false

  const orgHasAllMaps = !!permissions && !permissions.organizationSettings.maps
  const orgHasPermissions =
    !!permissions?.organizationSettings.maps?.includes(mapName) || orgHasAllMaps
  const regionHasPermissions = !!region?.allowedMaps?.includes(mapName)

  return orgHasPermissions && regionHasPermissions
}
