/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest'
import type { OpenAPIConfig } from './core/OpenAPI'
import { FetchHttpRequest } from './core/FetchHttpRequest'
import { LayersService } from './services/LayersService'
import { MdsAgencyService } from './services/MdsAgencyService'
import { MdsGeographiesService } from './services/MdsGeographiesService'
import { MdsPoliciesService } from './services/MdsPoliciesService'
import { MobilityMetricsService } from './services/MobilityMetricsService'
import { PoliciesV2Service } from './services/PoliciesV2Service'
import { RatesService } from './services/RatesService'
import { RegionsService } from './services/RegionsService'
import { RoutesService } from './services/RoutesService'
import { RulesService } from './services/RulesService'
import { SchedulesService } from './services/SchedulesService'
import { ShapesService } from './services/ShapesService'
import { UsersService } from './services/UsersService'
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest
export class ExternalV2Api {
  public readonly layers: LayersService
  public readonly mdsAgency: MdsAgencyService
  public readonly mdsGeographies: MdsGeographiesService
  public readonly mdsPolicies: MdsPoliciesService
  public readonly mobilityMetrics: MobilityMetricsService
  public readonly policiesV2: PoliciesV2Service
  public readonly rates: RatesService
  public readonly regions: RegionsService
  public readonly routes: RoutesService
  public readonly rules: RulesService
  public readonly schedules: SchedulesService
  public readonly shapes: ShapesService
  public readonly users: UsersService
  public readonly request: BaseHttpRequest
  constructor(
    config?: Partial<OpenAPIConfig>,
    HttpRequest: HttpRequestConstructor = FetchHttpRequest
  ) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '2.0.0',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    })
    this.layers = new LayersService(this.request)
    this.mdsAgency = new MdsAgencyService(this.request)
    this.mdsGeographies = new MdsGeographiesService(this.request)
    this.mdsPolicies = new MdsPoliciesService(this.request)
    this.mobilityMetrics = new MobilityMetricsService(this.request)
    this.policiesV2 = new PoliciesV2Service(this.request)
    this.rates = new RatesService(this.request)
    this.regions = new RegionsService(this.request)
    this.routes = new RoutesService(this.request)
    this.rules = new RulesService(this.request)
    this.schedules = new SchedulesService(this.request)
    this.shapes = new ShapesService(this.request)
    this.users = new UsersService(this.request)
  }
}
