/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MDSGeographiesResponse } from '../models/MDSGeographiesResponse'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class MdsGeographiesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * List Mds Policy Geographies
   * Return all of the geographies for the given region as a
   * to-spec MDS Geographies response:
   *
   * - https://github.com/openmobilityfoundation/mobility-data-specification/tree/1.2.0/geography#geographies
   * - https://github.com/openmobilityfoundation/mobility-data-specification/tree/1.2.0/geography#geography-fields
   * @returns MDSGeographiesResponse Successful Response
   * @throws ApiError
   */
  public listMdsPolicyGeographies({
    regionId,
  }: {
    regionId: string
  }): CancelablePromise<MDSGeographiesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mds/geographies',
      path: {
        region_id: regionId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
