/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { MDSGeographiesResponse } from '../models/MDSGeographiesResponse'
import type { Page_MDSPolicy_ } from '../models/Page_MDSPolicy_'
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class MdsPoliciesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * List Region Mds Policies
   * List all of the active MDS policies for the given region in the format we
   * want for our external facing maps pages.
   *
   * The schema of this response does not perfectly match that of MDS policies
   * (which is not paginated), but the individual policy documents are close.
   * @returns Page_MDSPolicy_ Successful Response
   * @throws ApiError
   */
  public listRegionMdsPolicies({
    regionId,
    page = 1,
    size = 50,
  }: {
    regionId: string
    /**
     * Page number
     */
    page?: number
    /**
     * Page size
     */
    size?: number
  }): CancelablePromise<Page_MDSPolicy_> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mds/policies',
      path: {
        region_id: regionId,
      },
      query: {
        page: page,
        size: size,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * Download Region Mds Policies
   * Allow the download of all of the active MDS policies.
   * @returns any Successful Response
   * @throws ApiError
   */
  public downloadRegionMdsPolicies({
    regionId,
    accept = 'application/json',
  }: {
    regionId: string
    accept?: string
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mds/policies/download',
      path: {
        region_id: regionId,
      },
      headers: {
        accept: accept,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
  /**
   * List Mds Policy Geographies For Policy
   * Return all of the geographies associated with a given MDS policy as a
   * to-spec MDS Geographies response:
   *
   * - https://github.com/openmobilityfoundation/mobility-data-specification/tree/1.2.0/geography#geographies
   * - https://github.com/openmobilityfoundation/mobility-data-specification/tree/1.2.0/geography#geography-fields
   * @returns MDSGeographiesResponse Successful Response
   * @throws ApiError
   */
  public listMdsPolicyGeographiesForPolicy({
    regionId,
    mdsPolicyId,
  }: {
    regionId: string
    mdsPolicyId: string
  }): CancelablePromise<MDSGeographiesResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/regions/{region_id}/mds/policies/{mds_policy_id}/geographies',
      path: {
        region_id: regionId,
        mds_policy_id: mdsPolicyId,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
