import { FeatureCollection, MultiPolygon, Polygon } from 'geojson'
import _, { get } from 'lodash'
import { action, computed, makeObservable, observable, ObservableMap, runInAction } from 'mobx'

import { fastApiRequest } from '@/modules/api/request'
import i18n from '@/modules/i18n/i18n'
import Layer, { LayerDocument, Level } from '@/modules/layers/shapeLayer'
import { monitorMessage } from '@/modules/monitoring'

export const CITYWIDE_LAYER_UUID = 'citywide'
export const getCitywideLayerName = () => i18n.t('common.citywideTitle', 'Citywide')
export const getCitywideLayerOption = (): LayerOption => ({
  text: getCitywideLayerName(),
  value: CITYWIDE_LAYER_UUID,
  level: undefined,
})

export interface LayerOption {
  text: string
  value: string
  level?: Level
}

/**
 * @deprecated Please use the useLayer hooks
 */
class LayerStore {
  layers: ObservableMap<string, Layer> = observable.map()
  loaded = false

  districts?: FeatureCollection<Polygon | MultiPolygon>
  districtIdToName?: { [shape_id: string]: string }
  districtUUIDToName?: { [shape_uuid: string]: string }

  constructor() {
    makeObservable(this, {
      loaded: observable,
      districts: observable,
      districtIdToName: observable,
      districtUUIDToName: observable,
      initializeRegion: action,
      clearRegion: action,
      availableLayerOptions: computed,
      availableVehicleCountOptions: computed,
      firstLayerUUID: computed,
      setLayers: action,
      clearLayers: action,
      updateDistrictIdToName: action,
      updateDistrictUUIDToName: action,
      updateDistricts: action,
    })
  }

  initializeRegion() {
    this.loaded = false
    this.districts = undefined
    this.districtIdToName = undefined
    this.districtUUIDToName = undefined
    return (
      this.loadLayers()
        // these promises now have to be chained - layers have to be loaded first
        // so that we can lookup the shape_layer_uuid for level l1 in fetchDistricts
        .then(() => this.fetchDistricts())
        .then(() => runInAction(() => (this.loaded = true)))
    )
  }

  clearRegion() {
    this.loaded = false
    this.districts = undefined
    this.districtIdToName = undefined
    this.districtUUIDToName = undefined
  }

  get availableLayerOptions(): LayerOption[] {
    return _([...this.layers.values()])
      .filter(l => !['blockface', 'cfad', 'parking_districts'].includes(l.level ?? ''))
      .sortBy([o => o.level, o => o.layerName])
      .map(o => ({
        text:
          o.level === 'l1'
            ? i18n.t('layerStore.primaryLayer', 'Primary Layer: {{layerName}}', {
                layerName: o.layerName,
              })
            : o.layerName,
        value: o.layerUUID,
        level: o.level,
      }))
      .value()
  }

  get availableVehicleCountOptions(): LayerOption[] {
    return this.availableLayerOptions.filter(o => this.getLayer(o.value)?.vehicleCounts)
  }

  getLayer(layerUUID: string | undefined | null) {
    if (layerUUID) return this.layers.get(layerUUID)
    return undefined
  }

  get firstLayerUUID() {
    return this.availableLayerOptions[0].value
  }

  setLayers(layers: LayerDocument[]) {
    layers.forEach(layer => {
      this.layers.set(layer.shape_layer_uuid, new Layer(layer))
    })
  }

  clearLayers() {
    this.layers.clear()
  }

  loadLayers() {
    this.clearLayers()
    return fastApiRequest('/layers/list')
      .then(response => {
        this.setLayers(response.data)
      })
      .catch(err => monitorMessage(`list_layers error: ${err}`))
  }

  updateDistrictIdToName(obj: { [shape_id: string]: string }) {
    this.districtIdToName = obj
  }

  updateDistrictUUIDToName(obj: { [shape_uuid: string]: string }) {
    this.districtUUIDToName = obj
  }

  updateDistricts(obj: FeatureCollection<Polygon | MultiPolygon>) {
    this.districts = obj
  }

  getLayerUUIDForLevel(level: string) {
    for (const layerUUID of this.layers.keys()) {
      if (this.layers.get(layerUUID)?.level === level) {
        return layerUUID
      }
    }
    return undefined
  }

  getLayerForLevel(level: string) {
    const shapeLayerUUID = this.getLayerUUIDForLevel(level)
    return this.getLayer(shapeLayerUUID!)
  }

  getLevelAsGeojson(level: string) {
    const layerUUID = this.getLayerUUIDForLevel(level)
    const layer = this.layers.get(layerUUID!) as Layer
    return layer.loadShapesFromDb()
  }

  fetchDistricts() {
    if (this.districts) {
      // already loaded
      return Promise.resolve()
    }

    return this.getLevelAsGeojson('l1')
      .then(geojson => {
        // make a map of ids to names for the district geojson file
        const obj = Object.fromEntries(
          geojson.features.map(f => [f.properties!.shape_id, f.properties!.name])
        )
        this.updateDistrictIdToName(obj)
        const obj2 = Object.fromEntries(
          geojson.features.map(f => [f.properties!.shape_uuid, f.properties!.name])
        )
        this.updateDistrictUUIDToName(obj2)
        this.updateDistricts(geojson as FeatureCollection<Polygon | MultiPolygon>)
      })
      .catch(() => {
        monitorMessage(`missing l1 shapes`)
      })
  }
}

/**
 * @deprecated Please use the useLayer hooks
 */
const layerStore = new LayerStore()

export default layerStore
