import { FC, Suspense, useEffect } from 'react'
import { createBrowserRouter, Navigate, Outlet, RouterProvider } from 'react-router'
import { useLocation } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import { ErrorBoundary, setUser as setSentryUser, wrapCreateBrowserRouterV7 } from '@sentry/react'
import { useAnalytics } from 'use-analytics'
import { QueryParamProvider } from 'use-query-params'
import { useLocalStorage } from 'usehooks-ts'

import { LocalStorageKey } from '@/common/utils/localStorage'
import { Banner } from '@/components/componentLibrary'
import NavigationLayout from '@/components/layouts/NavigationLayout'
import NavigationLayoutMobile from '@/components/layouts/NavigationLayoutMobile'
import NavigationLayoutMUI from '@/components/layouts/NavigationLayoutMUI'
import LoadAnimation from '@/components/loadAnimation'
import { useAuth } from '@/modules/authentication'
import Impersonate from '@/modules/authentication/Impersonate'
import LoginGatekeeper from '@/modules/authentication/LoginGatekeeper'
import MfaEnrollmentGatekeeper from '@/modules/authentication/MfaEnrollmentGatekeeper'
import DashboardLayout from '@/modules/dashboard/components/DashboardLayout'
import LogoutIdleUserTimer from '@/modules/idleTimer/LogoutIdleUserTimer'
import { useOrganization } from '@/modules/organization/hooks'
import {
  useGetCurbsLandingPage,
  useGetMobilityLandingPage,
} from '@/modules/permissions/pageAccessHooks'
import TermsOfServiceGateKeeper from '@/modules/termsOfService/TermsOfServiceGatekeeper'
import UpdateModal from '@/modules/update'
import { useCurrentRegion } from '@/modules/urlRouting/hooks'
import { PARAMS, PATHS, SEGMENTS } from '@/modules/urlRouting/paths'
import RegionGatekeeper, {
  NavigateToRegionLandingPage,
} from '@/modules/urlRouting/RegionGatekeeper'
import SyncRegionIdParam from '@/modules/urlRouting/SyncRegionIdParam'
import ReactRouter7Adapter from '@/modules/urlRouting/useQueryParamReactRouter7Adapter'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'
import { wrapPublicRoute, wrapRoute } from '@/modules/urlRouting/wrapRoute'
import { useCurrentUser } from '@/modules/user/hooks'
import WindowSizeModalMui from '@/modules/windowSize/WindowSizeModalMui'
import InvalidRoutePage from '@/pages/invalid-route'
import LoginPage from '@/pages/login'
import { EditState } from '@/pages/mobility/policies/_policy-id/constants'
import PolicyDetailsEditPage from '@/pages/mobility/policies/_policy-id/edit'
import OauthLoginClosingPage from '@/pages/oauth2/login/close'
import OIDCLoginPage from '@/pages/oidc/login'

const RootProviders: FC = () => (
  <QueryParamProvider adapter={ReactRouter7Adapter}>
    <Outlet />
  </QueryParamProvider>
)

const createBrowserRouterWithSentry = wrapCreateBrowserRouterV7(createBrowserRouter)

export const RootRouter: FC = () => {
  const router = createBrowserRouterWithSentry([
    {
      Component: RootProviders,
      errorElement: (
        <ErrorBoundary
          fallback={
            <Banner
              styleType="high-urgency"
              text="An error has occurred and has been logged. Please reload the page."
              className="empty-state-banner"
            />
          }
        />
      ),
      children: [
        { index: true, Component: LoggedInRoute },
        { path: PATHS.LOGIN, Component: LoginPage },
        { path: PATHS.LOGOUT, Component: Logout },
        { path: PATHS.OIDC.LOGIN, Component: OIDCLoginPage },
        {
          path: PATHS.OAUTH2.LOGIN.CLOSE,
          Component: OauthLoginClosingPage,
        },
        { path: PATHS.IMPERSONATE, Component: Impersonate },
        {
          path: `:${PARAMS.REGION_ID}`,
          Component: LoggedInRoute,
          children: [
            {
              index: true,
              Component: NavigateToRegionLandingPage,
            },
            {
              path: SEGMENTS.MOBILITY,
              Component: NavigationLayout,
              children: [
                {
                  index: true,
                  Component: NavigateToMobilityLandingPage,
                },
                {
                  path: '*',
                  Component: NavigateToMobilityLandingPage,
                },
                {
                  path: SEGMENTS.ACTIVITY_SUMMARY,
                  lazy: () => wrapRoute('mobility/activity-summary/index'),
                },
                {
                  path: SEGMENTS.MAPS,
                  children: [
                    {
                      index: true,
                      Component: NavigateToMobilityLandingPage,
                    },
                    { path: SEGMENTS.LIVE, lazy: () => wrapRoute('mobility/maps/live') },
                    { path: SEGMENTS['311'], lazy: () => wrapRoute('mobility/maps/311') },
                    {
                      path: SEGMENTS.DISTRIBUTION,
                      lazy: () => wrapRoute('mobility/maps/distribution'),
                    },
                    {
                      path: SEGMENTS.OPERATOR_DROP_OFFS,
                      lazy: () => wrapRoute('mobility/maps/operator-drop-offs'),
                    },
                    { path: SEGMENTS.PARKING, lazy: () => wrapRoute('mobility/maps/parking') },
                    { path: SEGMENTS.ROUTES, lazy: () => wrapRoute('mobility/maps/routes') },
                    { path: SEGMENTS.TRIPS, lazy: () => wrapRoute('mobility/maps/trips') },
                  ],
                },
                {
                  path: SEGMENTS.REPORTING,
                  children: [
                    {
                      index: true,
                      Component: NavigateToMobilityLandingPage,
                    },
                    {
                      path: SEGMENTS.TRIP_COUNTS,
                      lazy: () => wrapRoute('mobility/reporting/trip-counts/index'),
                    },
                    {
                      path: SEGMENTS.MOBILITY_METRICS,
                      lazy: () => wrapRoute('mobility/reporting/mobility-metrics/index'),
                    },
                    {
                      path: SEGMENTS.EXPORT,
                      lazy: () => wrapRoute('mobility/reporting/export'),
                    },
                    {
                      Component: DashboardLayout,
                      children: [
                        {
                          index: true,
                          Component: NavigateToMobilityLandingPage,
                        },
                        {
                          path: SEGMENTS.TRIP_DISTANCE,
                          lazy: () => wrapRoute('mobility/reporting/trip-distance'),
                        },
                        {
                          path: SEGMENTS.TRIP_DURATION,
                          lazy: () => wrapRoute('mobility/reporting/trip-duration'),
                        },
                        {
                          path: SEGMENTS.TIME_OF_DAY,
                          lazy: () => wrapRoute('mobility/reporting/time-of-day'),
                        },
                        {
                          path: SEGMENTS.TRIP_DISTANCE_TECH,
                          lazy: () => wrapRoute('mobility/reporting/trip-distance-by-tech'),
                        },
                        {
                          path: SEGMENTS.TRIP_SPEED_TECH,
                          lazy: () => wrapRoute('mobility/reporting/trip-speed-by-tech'),
                        },
                        {
                          path: SEGMENTS.PARKING_TIME,
                          lazy: () => wrapRoute('mobility/reporting/parking-time'),
                        },
                        {
                          path: SEGMENTS.PARKING_TIME_DISTRICT,
                          lazy: () => wrapRoute('mobility/reporting/parking-time-by-district'),
                        },
                      ],
                    },
                  ],
                },
                {
                  path: SEGMENTS.POLICIES,
                  children: [
                    { index: true, lazy: () => wrapRoute('mobility/policies/index') },
                    {
                      path: `:${PARAMS.POLICY_ID}`,
                      children: [
                        {
                          index: true,
                          lazy: () => wrapRoute('mobility/policies/_policy-id/index'),
                        },
                        {
                          path: SEGMENTS.EDIT,
                          element: <PolicyDetailsEditPage editState={EditState.EDIT} />,
                        },
                      ],
                    },

                    { path: SEGMENTS.CREATE, lazy: () => wrapRoute('mobility/policies/create') },
                    {
                      path: SEGMENTS.DUPLICATE,
                      lazy: () => wrapRoute('mobility/policies/duplicate'),
                    },
                    {
                      path: SEGMENTS.COMPLIANCE,
                      children: [
                        {
                          index: true,
                          lazy: () => wrapRoute('mobility/policies/compliance/index'),
                        },
                        {
                          path: `:${PARAMS.POLICY_ID}`,
                          lazy: () => wrapRoute('mobility/policies/compliance/details'),
                        },
                      ],
                    },
                  ],
                },
                { path: SEGMENTS.INVOICING, lazy: () => wrapRoute('mobility/invoicing') },
                { path: SEGMENTS.GEOGRAPHIES, lazy: () => wrapRoute('mobility/geographies') },
                {
                  path: SEGMENTS.MDS_DATA_HEALTH,
                  lazy: () => wrapRoute('mobility/mds-data-health/index'),
                },
              ],
            },
            {
              path: SEGMENTS.CURB,
              Component: NavigationLayoutMUI,
              children: [
                {
                  index: true,
                  Component: NavigateToCurbLandingPage,
                },
                {
                  index: '*',
                  Component: NavigateToCurbLandingPage,
                },
                {
                  path: SEGMENTS.REGULATIONS,
                  children: [
                    {
                      path: SEGMENTS.MAP,
                      lazy: () => wrapRoute('curb/management/regulations/index'),
                    },
                    {
                      path: SEGMENTS.LIBRARY,
                      lazy: () => wrapRoute('curb/management/regulationsLibrary/index'),
                    },
                  ],
                },
                {
                  path: SEGMENTS.MANAGEMENT,
                  children: [
                    { path: SEGMENTS.METRICS, lazy: () => wrapRoute('curb/metrics') },
                    { path: SEGMENTS.ACTIVITY, lazy: () => wrapRoute('curb/management/activity') },
                    {
                      index: true,
                      Component: NavigateToCurbLandingPage,
                    },
                  ],
                },
                {
                  path: SEGMENTS.GEOGRAPHIES,
                  children: [{ index: true, lazy: () => wrapRoute('curb/geographies') }],
                },
                {
                  path: SEGMENTS.PARKING_INSIGHTS,
                  children: [
                    { path: SEGMENTS.REVENUE, lazy: () => wrapRoute('curb/revenue') },
                    { path: SEGMENTS.TRANSACTIONS, lazy: () => wrapRoute('curb/transactions') },
                    { path: SEGMENTS.PRICING_ENGINE, lazy: () => wrapRoute('curb/pricing-engine') },
                    { path: SEGMENTS.OCCUPANCY, lazy: () => wrapRoute('curb/occupancy') },
                    {
                      index: true,
                      Component: NavigateToCurbLandingPage,
                    },
                  ],
                },
                {
                  path: SEGMENTS.REVENUE,
                  children: [
                    {
                      index: true,
                      Component: NavigateToCurbLandingPage,
                    },
                    {
                      path: SEGMENTS.FEES_AND_REVENUE,
                      lazy: () => wrapRoute('curb/fees-and-revenue'),
                    },
                  ],
                },
              ],
            },
            {
              path: SEGMENTS.MOBILE,
              Component: NavigationLayoutMobile,
              children: [
                {
                  index: true,
                  Component: NavigateToMobilityLandingPage,
                },
                {
                  path: SEGMENTS.LIVE,
                  lazy: () => wrapRoute('mobile/live'),
                },
              ],
            },
            { path: '*', Component: NavigateToRegionLandingPage },
          ],
        },
        {
          path: `:${PARAMS.REGION_ID}/${SEGMENTS.PUBLIC}`,
          children: [
            { path: SEGMENTS.ROUTES, lazy: () => wrapPublicRoute('public/routes') },
            { path: SEGMENTS.REGULATIONS, lazy: () => wrapPublicRoute('public/regulations') },
            { path: SEGMENTS.POLICIES, lazy: () => wrapPublicRoute('public/policies') },
          ],
        },
      ],
    },
  ])
  return <RouterProvider router={router} />
}

const Logout: FC = () => {
  const { signOut } = useAuth()
  signOut()

  return <Navigate to={PATHS.ROOT} replace state={{ from: undefined }} />
}

const LoggedInRoute: FC = () => (
  <LoginGatekeeper>
    <MfaEnrollmentGatekeeper>
      <LogoutIdleUserTimer>
        <Suspense fallback={<LoadAnimation />}>
          <TermsOfServiceGateKeeper>
            <ProtectedRoutes />
          </TermsOfServiceGateKeeper>
        </Suspense>
      </LogoutIdleUserTimer>
    </MfaEnrollmentGatekeeper>
  </LoginGatekeeper>
)

const ProtectedRoutes: FC = () => {
  const location = useLocation()

  const analytics = useAnalytics()
  const { data: currentUser } = useAuth()
  const { boot: bootIntercom } = useIntercom()
  const { data: user } = useCurrentUser()
  const {
    data: { organizationName },
  } = useOrganization(user.organizationId)
  const [previouslyLoggedInUser, setPreviouslyLoggedInUser] = useLocalStorage<{
    providers: undefined | string[]
    email: string | null
  }>(LocalStorageKey.USER, { providers: undefined, email: '' })

  useEffect(() => {
    if (currentUser && user) {
      bootIntercom({
        email: user.email,
        userId: currentUser.uid,
        company: { companyId: user.organizationId, name: organizationName },
      })
      setSentryUser({
        userId: user.id,
        email: user.email,
        organization: organizationName,
      })
      analytics.identify(user.email, {
        userId: user.id,
        organization: organizationName,
      })
    }
  }, [currentUser, user])

  useEffect(() => {
    analytics.page() // send page view on route change
  }, [location, analytics])

  useEffect(() => {
    if (currentUser && previouslyLoggedInUser.email !== currentUser.email) {
      setPreviouslyLoggedInUser({
        email: currentUser.email,
        providers: currentUser.providerData.map(provider => provider?.providerId),
      })
    }
  }, [currentUser, previouslyLoggedInUser, setPreviouslyLoggedInUser])

  return (
    <div>
      <UpdateModal />
      <RegionGatekeeper>
        <SyncRegionIdParam>
          <WindowSizeModalMui />
          <Outlet />
        </SyncRegionIdParam>
      </RegionGatekeeper>
    </div>
  )
}

const NavigateToMobilityLandingPage: FC = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()
  const mobilityLandingPage = useGetMobilityLandingPage()

  return regionId ? (
    <Navigate to={generatePathWithRegionId(mobilityLandingPage, regionId)} />
  ) : (
    <InvalidRoutePage />
  )
}

const NavigateToCurbLandingPage: FC = () => {
  const {
    data: { regionId },
  } = useCurrentRegion()
  const curbLandingPage = useGetCurbsLandingPage()

  return regionId ? (
    <Navigate to={generatePathWithRegionId(curbLandingPage, regionId)} />
  ) : (
    <InvalidRoutePage />
  )
}
