/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise'
import type { BaseHttpRequest } from '../core/BaseHttpRequest'
export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get User Onboarding Link
   * @returns any Successful Response
   * @throws ApiError
   */
  public getUserOnboardingLink({
    onboardingUuid,
  }: {
    /**
     * User onboarding identifier
     */
    onboardingUuid: string
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/users/get_user_onboarding_link/{onboarding_uuid}',
      path: {
        onboarding_uuid: onboardingUuid,
      },
      errors: {
        422: `Validation Error`,
      },
    })
  }
}
