import { FC, PropsWithChildren, useEffect } from 'react'
import { Navigate, useParams } from 'react-router'
import { useIntercom } from 'react-use-intercom'
import { useLocalStorage, useReadLocalStorage } from 'usehooks-ts'

import { LocalStorageKey } from '@/common/utils/localStorage'
import { Button, Text } from '@/components/componentLibrary'
import { InvalidRouteTemplate } from '@/components/layouts'
import LoadAnimation from '@/components/loadAnimation'
import { monitorBreadcrumb } from '@/modules/monitoring'
import { useRegion, useRegions } from '@/modules/urlRouting/hooks'
import { generatePathWithRegionId } from '@/modules/urlRouting/utils'
import { useCurrentPermissions } from '@/modules/user/hooks'

import { useGetMobilityLandingPage } from '../permissions/pageAccessHooks'
import { useGetCurbsLandingPage } from '../permissions/pageAccessHooks'

/**
 * Redirect to region with default home page when missing in path. For example:
 * - /oakland/maps (valid region id with Access) => do nothing
 * - /berkeley (valid region id but no access) => redirect to /:defaultRegionId
 * - /blah (invalid region id) = redirect to /:defaultRegionId
 *
 */
const RegionGatekeeper: FC<PropsWithChildren> = ({ children }) => {
  const { regionId } = useParams()
  const { data: region, isLoading: isLoading } = useRegion(regionId)

  if (isLoading) return <LoadAnimation />

  if (!region) {
    monitorBreadcrumb(
      'RegionGatekeeper: No Access to Region (or region does not exist), Pushing to Default Region'
    )
    return <NavigateToDefaultRegion />
  }

  return <RegionLocalStorageSync>{children}</RegionLocalStorageSync>
}

const RegionLocalStorageSync: FC<PropsWithChildren> = ({ children }) => {
  const { regionId } = useParams()
  const [localStorageRegionId, setLocalStorageRegionId] = useLocalStorage<string>(
    LocalStorageKey.REGION_ID,
    ''
  )

  useEffect(() => {
    if (regionId && localStorageRegionId != regionId) setLocalStorageRegionId(regionId)
  }, [regionId, localStorageRegionId, setLocalStorageRegionId])

  return children
}

/**
 * Provided a regionId, redirects to a default "Home Page" for a user:
 *  - if user has mobility manager => Live Map
 *  - else if user has curb manager => Curb Rules
 */
export const NavigateToRegionLandingPage: FC = () => {
  const { data: permissions } = useCurrentPermissions()
  const { regionId } = useParams()
  const mobilityLandingPage = useGetMobilityLandingPage()
  const curbsLandingPage = useGetCurbsLandingPage()

  const homePagePath = permissions?.organizationSettings.mobilityManager
    ? mobilityLandingPage
    : permissions?.organizationSettings.curbManager
      ? curbsLandingPage
      : undefined

  monitorBreadcrumb(`Navigation: Push to Default page for Region: ${regionId}`)

  if (!homePagePath) return <Navigate to={`/${regionId}/404`} />

  return <Navigate to={generatePathWithRegionId(homePagePath, regionId)} />
}

export const NavigateToDefaultRegion: FC = () => {
  const { regionId } = useParams()
  const localStorageRegionId = useReadLocalStorage<string>(LocalStorageKey.REGION_ID)
  const { data: localStorageRegion, isLoading: isLoadingRegion } = useRegion(
    localStorageRegionId ?? undefined
  )
  const { data: regions, isLoading: isLoadingRegions } = useRegions()

  monitorBreadcrumb('Navigation: Push to Default Region')

  if (isLoadingRegion || isLoadingRegions) return <LoadAnimation />

  if (localStorageRegion?.regionId && localStorageRegion?.regionId != regionId)
    return <Navigate to={`/${localStorageRegion.regionId}`} />

  if (regions?.pages[0].items[0].regionId)
    return <Navigate to={`/${regions.pages[0].items[0].regionId}`} />

  return <NoRegionAvailablePage />
}

const NoRegionAvailablePage: FC = () => {
  const { showNewMessage } = useIntercom()

  return (
    <InvalidRouteTemplate
      primaryTextContent={
        <Text styleType="title-light">You do not have access to any Regions</Text>
      }
      secondaryTextContent={
        <Text styleType="body">
          If this problem persists, contact your Populus plan administrator.
        </Text>
      }
      actionsContent={
        <Button extraWide onClick={() => showNewMessage()} small text="Contact The Populus Team" />
      }
    />
  )
}

export default RegionGatekeeper
