import { useState } from 'react'
import { useTranslation } from 'react-i18next'

import { SettingsShelfItem } from '@/components/Shelf/SettingsShelfItem'
import {
  ShelfDropdown,
  ShelfDropdownDivider,
  ShelfDropdownHeader,
  ShelfDropdownItem,
} from '@/components/Shelf/ShelfDropdown'
import { UserAvatar } from '@/components/UserAvatar'
import { PermissionKey } from '@/modules/api/openapi/models/PermissionKey'
import { useAuth } from '@/modules/authentication'
import { GlobalModalId, useGlobalModalProvider } from '@/modules/globalModal/context'
import { useProfilePageAccess } from '@/modules/urlRouting/utils'
import { useCurrentUser, usePermission } from '@/modules/user/hooks'

const SettingsLinks = () => {
  const { t } = useTranslation()
  const { setId } = useGlobalModalProvider()
  const { signOut } = useAuth()
  const { data: user } = useCurrentUser()

  const [settingsOpen, setSettingsOpen] = useState(false)

  const hasAdmin = usePermission(PermissionKey.IS_ADMIN)
  const hasProfilePageAccess = useProfilePageAccess()

  return (
    <>
      <ShelfDropdown
        onBlur={() => setSettingsOpen(false)}
        open={settingsOpen}
        trigger={
          <SettingsShelfItem
            active={settingsOpen}
            dataTestId="settings"
            iconName="Settings"
            onClick={() => setSettingsOpen(!settingsOpen)}
            text={t('settingsLinks.settings', 'Settings')}
          />
        }
      >
        <ShelfDropdownHeader>
          <div>
            <UserAvatar email={user?.email} initials={user.email[0]} />
          </div>
        </ShelfDropdownHeader>
        <ShelfDropdownDivider />
        <ShelfDropdownItem
          disabled={!hasProfilePageAccess}
          onClick={() => hasProfilePageAccess && setId(GlobalModalId.PROFILE_SETTINGS)}
          text={t('settingsLinks.profileSettings', 'Profile Settings')}
        />
        <ShelfDropdownItem onClick={signOut} text={t('settingsLinks.signOut', 'Sign Out')} />
        {hasAdmin && (
          <>
            <ShelfDropdownDivider />
            <ShelfDropdownItem
              onClick={() =>
                hasAdmin &&
                window.open(
                  import.meta.env.DEV
                    ? `${window.location.protocol}//${window.location.hostname}:8000/admin`
                    : `${window.location.origin}/admin`,
                  '_blank'
                )
              }
              text={t('settingsLinks.admin', 'Admin')}
            />
          </>
        )}
      </ShelfDropdown>
      <SettingsShelfItem
        iconName="Help"
        onClick={() => window.open('https://help.populus.ai/en/', '_blank')}
        text={t('settingsLinks.help', 'Help')}
      />
    </>
  )
}

export default SettingsLinks
